export const serverValidationToForm = ({
  errors,
  setFieldError,
  errorFieldMap
}) => {
  if (setFieldError) {
    errors.forEach(({ field, messages }) => {
      if (errorFieldMap && field in errorFieldMap) {
        if (Array.isArray(messages)) {
          setFieldError(errorFieldMap[field], messages[0])
        } else {
          setFieldError(errorFieldMap[field], messages)
        }
      } else if (field === 'response') {
        console.log(messages)
      } else {
        if (Array.isArray(messages)) {
          setFieldError(field, messages[0])
        } else {
          setFieldError(field, messages)
        }
      }
    })

    Object.entries(errors).filter(([k, v]) => k !== 'response').forEach(([k, v]) => {
      if (errorFieldMap && k in errorFieldMap) {
        if (Array.isArray(v)) {
          setFieldError(errorFieldMap[k], v[0])
        } else {
          setFieldError(errorFieldMap[k], v)
        }
      } else {
        if (Array.isArray(v)) {
          setFieldError(k, v[0])
        } else {
          setFieldError(k, v)
        }
      }
    })
  }
}
