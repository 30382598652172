import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import gql from 'graphql-tag'
import * as yup from 'yup'
import { withFormik } from 'formik'
import { compose, withApollo, graphql } from 'react-apollo'
import { serverValidationToForm } from '../../utils/formUtils'

import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn
} from 'mdbreact'

const BANNED_WORD_MUTATION = gql`
mutation createWord($word: String!, $type: String!, $description: String ) {
  createBannedWord(input: { word: $word, type: $type, description: $description }) {
    bannedWord {
      word
      description
      type
    }
    errors {
      field
      messages
      path
    }
  }
}
`

const enhancer = withFormik({
  validationSchema: yup.object().shape({
    word: yup
      .string()
      .lowercase()
      .required()
      .label('Word'),
    type: yup
      .string()
      .lowercase()
      .required()
      .label('Type'),
    description: yup
      .string()
      .label('Description')
  }),

  mapPropsToValues: props => ({
    word: '',
    type: '',
    description: ''
  }),

  handleSubmit: async (values, { props, setSubmitting, setFieldError }) => {
    let mutation, errors
    mutation = await props.createBannedWord({
      variables: {
        word: values.word,
        type: values.type,
        description: values.description
      }
    })
    errors = mutation.data.createBannedWord.errors

    setSubmitting(false)

    if (errors) {
      serverValidationToForm({
        errors,
        setFieldError,
        errorFieldMap: { response: 'word' }
      })
    } else {
      props.onClose()
    }
  }
})

class BannedWordModal extends React.Component {
  static propTypes = {
    values: PropTypes.shape({
      word: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      description: PropTypes.string
    }),
    status: PropTypes.string,
    errors: PropTypes.object,
    setStatus: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool
  }
  render () {
    let { values, status, errors, onClose, handleSubmit, handleBlur, handleChange, isSubmitting, setStatus } = this.props
    return (
      <MDBModal isOpen={true} toggle={onClose} size="lg">
        <form onSubmit={handleSubmit} noValidate>
          <MDBModalHeader toggle={onClose}>
            Add a Banned Word
          </MDBModalHeader>
          <MDBModalBody>
            <div className="form-group">
              <label htmlFor="word">Word</label>
              <input
                id="word"
                name="word"
                type="text"
                value={values.word}
                onBlur={handleBlur}
                onChange={handleChange}
                className={classNames({
                  'form-control': true,
                  'is-invalid':
                    status === 'submitted' && errors.word
                })}
              />
              {status === 'submitted' && errors.word && (
                <div className="invalid-feedback d-block">
                  {errors.word}
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="type">Type</label>
              <input
                id="type"
                name="type"
                type="text"
                value={values.type}
                onBlur={handleBlur}
                onChange={handleChange}
                className={classNames({
                  'form-control': true,
                  'is-invalid':
                    status === 'submitted' && errors.type
                })}
              />
              {status === 'submitted' && errors.type && (
                <div className="invalid-feedback d-block">
                  {errors.type}
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="type">Description</label>
              <input
                id="description"
                name="description"
                type="text"
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
                className={classNames({
                  'form-control': true,
                  'is-invalid':
                    status === 'submitted' && errors.description
                })}
              />
              {status === 'submitted' && errors.description && (
                <div className="invalid-feedback d-block">
                  {errors.description}
                </div>
              )}
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="primary"
              disabled={isSubmitting}
              type="submit"
              onClick={() => setStatus('submitted')}
            >
              Save
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </MDBModal>
    )
  }
}

export default compose(
  withApollo,
  graphql(BANNED_WORD_MUTATION, { name: 'createBannedWord' }),
  enhancer
)(BannedWordModal)
