import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import OrderList from '../components/OrderList'
import ProductList from '../components/ProductList'
import { formatDateTime } from '../utils/dateUtils'

import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  Table,
  TableBody
} from 'mdbreact'

import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import { ORDER_LIST_PARTS } from './OrderListPage'

const GET_STORE = gql`
  ${ORDER_LIST_PARTS}

  query Store($id: ID!) {
    store(id: $id) {
      id
      shortId
      name
      productCount
      orderCount
      orderSum
      coverPhoto
      createdAt
      owner {
        name
        email
      }
      products {
        totalCount
        edges {
          node {
            id
            name
            price
            tag {
              id
              name
            }
            description
            stockQuantity
            scannedBarcode
            isEnabled
          }
        }
      }
      orders(first: 10) {
        edges {
          cursor
          node {
            ...OrderListParts
          }
        }
      }
    }
  }
`

export default class StorePage extends Component {
  static propTypes = {
    match: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      selectedTab: 'orders'
    }
  }

  handleTabClick (tab) {
    this.setState({ selectedTab: tab })
  }

  render () {
    let { selectedTab } = this.state
    return (
      <Query query={GET_STORE} variables={{ id: this.props.match.params.id }}>
        {({ loading, error, data, fetchMore }) => {
          if (loading) return 'Loading...'
          if (error) return `Error! ${error.message}`
          let { orders, products, owner } = data.store

          return (
            <React.Fragment>
              <MDBBreadcrumb color="white">
                <MDBBreadcrumbItem>
                  <Link to="/">Home</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                  <Link to="/stores">Stores</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>{data.store.name}</MDBBreadcrumbItem>
              </MDBBreadcrumb>
              <MDBRow>
                <MDBCol size="4">
                  <MDBCard>
                    <MDBCardImage
                      className="img-fluid"
                      src={data.store.coverPhoto}
                      waves
                    />
                    <MDBCardBody>
                      <React.Fragment>
                        <h1>{data.store.name}</h1>
                      </React.Fragment>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
                <MDBCol size="8">
                  <MDBCard>
                    <MDBCardBody>
                      <React.Fragment>
                        <h2 className="mb-4">Info</h2>
                        <Table borderless hover>
                          <TableBody>
                            <tr>
                              <th>Store ID</th>
                              <td>
                                <a
                                  href={`https://www.hausmart.com/store/${
                                    data.store.shortId
                                  }?notify=false`}
                                  style={{ color: '#de1475' }}
                                >
                                  {data.store.shortId}
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <th>Owner</th>
                              <td>
                                {owner.name}{' '}
                                <span className="text-muted">
                                  ({owner.email})
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th>Product count</th>
                              <td>{data.store.productCount}</td>
                            </tr>
                            <tr>
                              <th>Order count</th>
                              <td>{data.store.orderCount}</td>
                            </tr>
                            <tr>
                              <th>Sales sum</th>
                              <td>
                                {new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: data.store.currency || 'USD'
                                }).format(data.store.orderSum)}
                              </td>
                            </tr>
                            <tr>
                              <th>Created</th>
                              <td>{formatDateTime(data.store.createdAt)}</td>
                            </tr>
                          </TableBody>
                        </Table>
                      </React.Fragment>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="8" className="text-right align-bottom" />
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <div className="classic-tabs">
                    <ul
                      className="nav nav-pills"
                      style={{ padding: '10px 5px' }}
                    >
                      <li className="nav-item">
                        <div
                          className={`nav-link ${
                            selectedTab === 'orders' ? 'active' : ''
                          }`}
                          onClick={() => this.handleTabClick('orders')}
                        >
                          Orders
                        </div>
                      </li>
                      <li className="nav-item">
                        <div
                          className={`nav-link ${
                            selectedTab === 'products' ? 'active' : ''
                          }`}
                          onClick={() => this.handleTabClick('products')}
                        >
                          Products
                        </div>
                      </li>
                    </ul>

                    {selectedTab === 'orders' && (
                      <div>
                        <OrderList entries={orders} />

                        <a href={`/orders?store=${data.store.id}`}>See all</a>
                      </div>
                    )}
                    {selectedTab === 'products' && (
                      <div>
                        <ProductList entries={products} />
                        <p>{products.totalCount} results</p>
                      </div>
                    )}
                  </div>
                </MDBCol>
              </MDBRow>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
}
