import React from 'react'
import PropTypes from 'prop-types'

import { Table, TableBody, TableHead, MDBIcon } from 'mdbreact'

const BarcodeImageList = ({ entries: { totalCount, edges } }) => (
  <>
    {totalCount && <span>{totalCount} results</span>}
    <div>
      <Table>
        <TableHead textWhite color="unique-color-dark">
          <tr>
            <th>Barcode</th>
            <th>Barcode Type</th>
            <th>Product Name</th>
            <th>Product Brand</th>
            <th>Is Verified</th>
          </tr>
        </TableHead>

        <TableBody color="white">
          {edges.map(({ node }) => (
            <tr key={node.id}>
              <td className="small text-muted text-monospace">
                {node.gtinCode}
              </td>
              <td>{node.gtinType}</td>
              <td>{node.productName}</td>
              <td>{node.productBrand}</td>
              <td>
                <MDBIcon
                  icon={node.isVerified ? 'check' : 'times'}
                  fixed
                  className={node.isVerified ? 'text-success' : 'text-danger'}
                />
              </td>
            </tr>
          ))}
        </TableBody>
      </Table>
    </div>
  </>
)

BarcodeImageList.propTypes = {
  entries: PropTypes.shape({
    totalCount: PropTypes.number,
    edges: PropTypes.array.isRequired
  })
}

export default BarcodeImageList
