import React, { Component } from 'react'

import { Card, CardBody, MDBIcon, Button } from 'mdbreact'

import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import StoreList from '../components/StoreList'
import Pagination from '../components/Pagination'
import DownloadModal from '../components/modals/DownloadModal'

const PAGE_SIZE = 10

const GET_STORES = gql`
  query Stores($first: Int, $after: String, $search: String) {
    stores(first: $first, after: $after, search: $search) {
      edges {
        cursor
        node {
          id
          shortId
          name
          createdAt
          productCount
          orderCount
          orderSum
          owner {
            name
            email
          }
        }
      }
      totalCount
    }
  }
`

const GET_REPORT_URL = gql`
  {
    downloadToken {
      storeReport
    }
  }
`

export class StoreListPage extends Component {
  static propTypes = {}

  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      typing: false,
      typingTimeout: 0,
      search: '',
      currentSearch: '',
      showDownloadModal: false
    }
  }

  typeSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout)
    }

    this.setState({
      search: event.target.value,
      typing: true,
      typingTimeout: setTimeout(() => {
        this.search()
      }, 500)
    })
  }

  search = () =>
    this.setState({
      currentSearch: this.state.search,
      currentPage: 1,
      typing: false
    })

  async handleStoreReport (client) {
    // TODO: Filter
    const query = await client.query({
      query: GET_REPORT_URL
    })

    this.setState({
      downloadURL: query.data.downloadToken.storeReport,
      showDownloadModal: true
    })
  }

  toggleDownloadModal () {
    this.setState({ showDownloadModal: !this.state.showDownloadModal })
  }

  render () {
    let {
      showDownloadModal,
      downloadURL,
      search,
      currentSearch,
      currentPage
    } = this.state
    return (
      <Card>
        <CardBody>
          <h1>Store Search</h1>

          <div className="input-group md-form form-sm form-1 pl-0">
            <div className="input-group-prepend">
              <span className="input-group-text lighten-3" id="basic-text1">
                <MDBIcon className="text-white" icon="search" />
              </span>
            </div>
            <input
              className="form-control my-0 py-1"
              type="text"
              placeholder="Search"
              aria-label="Search"
              value={search}
              onChange={this.typeSearch}
            />
          </div>

          <Query
            query={GET_STORES}
            variables={{ first: PAGE_SIZE, search: currentSearch }}
            fetchPolicy="network-only"
          >
            {({ client, loading, error, data, fetchMore }) => {
              if (loading) return 'Loading...'
              if (error) return `Error! ${error.message}`

              return (
                <>
                  <div className="pull-right">
                    <Button
                      id="export-button"
                      color="primary"
                      size="sm"
                      onClick={() => this.handleStoreReport(client)}
                    >
                      <MDBIcon icon="file-excel-o" fixed /> Store Report
                    </Button>
                  </div>
                  <Pagination
                    pageSize={PAGE_SIZE}
                    totalCount={data.stores.totalCount || 0}
                    currentPage={currentPage}
                    fetchMore={fetchMore}
                    setCurrentPage={page =>
                      this.setState({ currentPage: page })
                    }
                  >
                    <StoreList entries={data.stores || []} />
                  </Pagination>
                </>
              )
            }}
          </Query>
          {showDownloadModal && (
            <DownloadModal
              toggle={() => this.toggleDownloadModal()}
              downloadURL={downloadURL}
            />
          )}
        </CardBody>
      </Card>
    )
  }
}

export default StoreListPage
