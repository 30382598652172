import React, {
  Component
} from 'react'
import PropTypes from 'prop-types'
import {
  graphql
} from 'react-apollo'
import gql from 'graphql-tag'
import {
  withRouter
} from 'react-router'

const currentUserQuery = gql`
  query {
    getMe {
      id, email, name
    }
  }
`

export default (ComponentToExtend) => {
  class CurrentUserHOC extends Component {
    render () {
      const currentUserData = this.props.currentUserData

      if (!currentUserData.getMe) {
        return false
      }

      if (currentUserData.loading) {
        return false
      }

      return (<
        ComponentToExtend { ...this.props
        }
        currentUser = {
          currentUserData.getMe
        }
      />
      )
    }
  }

  CurrentUserHOC.contextTypes = {
    router: PropTypes.object.isRequired
  }

  return graphql(currentUserQuery, {
    name: 'currentUserData',
    options: {
      pollInterval: 300000
    }
  })(withRouter(CurrentUserHOC))
}
