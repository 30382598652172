import React, { Component } from 'react'

import { Card, CardBody, MDBIcon, Button } from 'mdbreact'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import FunnelReportList from '../components/FunnelReportList'
import Pagination from '../components/Pagination'
import DownloadModal from '../components/modals/DownloadModal'

const PAGE_SIZE = 10

const EXPORT_QUERY = gql`
  query FunnelReport(
    $first: Int
    $after: String
    $orderBy: String
    $email: String
    $campaign: String
    $shortId: String
    $appInstalled: Boolean
    $lastLoginWith: String
    $paypalEmail: String
    $isRenamed: Boolean
    $isClaimed: Boolean
    $hasProducts: Boolean
    $lastLoginBefore: DateTime
    $lastLoginAfter: DateTime
  ) {
    templateStoreClaims(
      first: $first
      after: $after
      orderBy: $orderBy
      email: $email
      campaign: $campaign
      shortId: $shortId
      appInstalled: $appInstalled
      lastLoginWith: $lastLoginWith
      paypalEmail: $paypalEmail
      isRenamed: $isRenamed
      unusedClaims: true
      isClaimed: $isClaimed
      hasProducts: $hasProducts
      lastLoginBefore: $lastLoginBefore
      lastLoginAfter: $lastLoginAfter
    ) {
      reportUrl
    }
  }
`

const REPORT_QUERY = gql`
  query FunnelReport(
    $first: Int
    $after: String
    $orderBy: String
    $email: String
    $campaign: String
    $shortId: String
    $appInstalled: Boolean
    $lastLoginWith: String
    $paypalEmail: String
    $isRenamed: Boolean
    $isClaimed: Boolean
    $hasProducts: Boolean
    $lastLoginBefore: DateTime
    $lastLoginAfter: DateTime
  ) {
    templateStoreClaims(
      first: $first
      after: $after
      orderBy: $orderBy
      email: $email
      campaign: $campaign
      shortId: $shortId
      appInstalled: $appInstalled
      lastLoginWith: $lastLoginWith
      paypalEmail: $paypalEmail
      isRenamed: $isRenamed
      unusedClaims: true
      isClaimed: $isClaimed
      hasProducts: $hasProducts
      lastLoginBefore: $lastLoginBefore
      lastLoginAfter: $lastLoginAfter
    ) {
      edges {
        node {
          shortId
          isRenamed
          requestEmail
          claimDeviceId
          campaign {
            code
            name
          }
          createdAt
          isClaimed
          store {
            shortId
            name
            productCount
            createdAt
            owner {
              id
              email
              name
              lastLoginWith
              lastLogin
              createdAt
            }
          }
          templateStore {
            name
          }
        }
      }
      totalCount
    }
  }
`

export class FunnelReportPage extends Component {
  static propTypes = {}

  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      typing: false,
      typingTimeout: 0,
      search: '',
      currentSearch: '',
      orderBy: '-createdAt',
      showFilters: false,
      emailFilter: null,
      campaignFilter: null,
      shortIdFilter: null,
      appInstalledFilter: null,
      lastLoginWithFilter: null,
      isRenamedFilter: null,
      claimedDatePickerFocus: 'startDate',
      isClaimedFilter: null,
      hasProductsFilter: null,
      showDownloadModal: false,
      downloadURL: ''
    }
  }

  typeSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout)
    }

    this.setState({
      search: event.target.value,
      typing: true,
      typingTimeout: setTimeout(() => {
        this.search()
      }, 500)
    })
  }

  search = () => {
    this.setState({
      currentSearch: this.state.search,
      currentPage: 1,
      typing: false
    })
  }

  updateOrderBy (fieldName) {
    let newOrder =
      this.state.orderBy.indexOf(fieldName) === 0 ? `-${fieldName}` : fieldName
    this.setState({ orderBy: newOrder })
  }

  setClaimedDatePickerFocus (claimedDatePickerFocus) {
    this.setState({ claimedDatePickerFocus })
  }

  updateDateFilter (name, before, after) {
    this.setState({ [name + 'Before']: before, [name + 'After']: after })
  }

  async handleExport (client, variables) {
    const query = await client.query({
      query: EXPORT_QUERY,
      variables
    })

    this.setState({
      downloadURL: query.data.templateStoreClaims.reportUrl,
      showDownloadModal: true
    })
  }

  toggleDownloadModal () {
    this.setState({ showDownloadModal: !this.state.showDownloadModal })
  }

  render () {
    let {
      showFilters,
      currentPage,
      orderBy,
      currentSearch,
      emailFilter,
      campaignFilter,
      shortIdFilter,
      appInstalledFilter,
      lastLoginWithFilter,
      isRenamedFilter,
      isClaimedFilter,
      hasProductsFilter,
      lastLoginAfter,
      lastLoginBefore,
      showDownloadModal,
      downloadURL
    } = this.state
    let variables = {
      first: PAGE_SIZE,
      orderBy: orderBy,
      search: currentSearch,
      email: emailFilter,
      campaign: campaignFilter,
      shortId: shortIdFilter,
      appInstalled: appInstalledFilter,
      lastLoginWith: lastLoginWithFilter,
      isRenamed: isRenamedFilter,
      isClaimed: isClaimedFilter,
      hasProducts: hasProductsFilter,
      lastLoginAfter: lastLoginAfter && lastLoginAfter.startOf('day'),
      lastLoginBefore:
        lastLoginBefore && lastLoginBefore.endOf('day')
    }

    return (
      <Card>
        <CardBody>
          <div className="pull-right" />
          <h1>Funnelytics</h1>

          <div className="input-group md-form form-sm form-1 pl-0" />

          <Query
            query={REPORT_QUERY}
            fetchPolicy="network-only"
            variables={variables}
          >
            {({ client, loading, error, data, fetchMore, refetch }) => {
              if (loading) return 'Loading...'
              if (error) return `Error! ${error.message}`

              return (
                <>
                  <div className="pull-right">
                    <Button
                      id="filter-button"
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.setState({ showFilters: !showFilters })
                      }
                    >
                      <MDBIcon icon={'filter'} fixed />
                      Filter
                    </Button>
                    <Button
                      id="export-button"
                      color="primary"
                      size="sm"
                      onClick={() => this.handleExport(client, variables)}
                    >
                      <MDBIcon icon="file-excel-o" fixed /> XLS
                    </Button>
                  </div>
                  <Pagination
                    pageSize={PAGE_SIZE}
                    totalCount={data.templateStoreClaims.totalCount || 0}
                    currentPage={currentPage}
                    fetchMore={fetchMore}
                    setCurrentPage={page =>
                      this.setState({ currentPage: page })
                    }
                  >
                    <FunnelReportList
                      items={data.templateStoreClaims || []}
                      emailFilter={emailFilter}
                      campaignFilter={campaignFilter}
                      shortIdFilter={shortIdFilter}
                      showFilters={showFilters}
                      appInstalledFilter={appInstalledFilter}
                      lastLoginWithFilter={lastLoginWithFilter}
                      isRenamedFilter={isRenamedFilter}
                      isClaimedFilter={isClaimedFilter}
                      hasProductsFilter={hasProductsFilter}
                      lastLoginAfter={lastLoginAfter}
                      lastLoginBefore={lastLoginBefore}
                      orderBy={orderBy}
                      updateOrderBy={fieldName => this.updateOrderBy(fieldName)}
                      updateFilter={({ filterName, value }) =>
                        this.setState({ [filterName]: value })
                      }
                      setClaimedDatePickerFocus={focus =>
                        this.setClaimedDatePickerFocus(focus)
                      }
                      updateDateFilter={(name, before, after) =>
                        this.updateDateFilter(name, before, after)
                      }
                    />
                  </Pagination>
                </>
              )
            }}
          </Query>
          {showDownloadModal && (
            <DownloadModal
              toggle={() => this.toggleDownloadModal()}
              downloadURL={downloadURL}
            />
          )}
        </CardBody>
      </Card>
    )
  }
}

export default FunnelReportPage
