import React from 'react'
import PropTypes from 'prop-types'

import Moment from 'react-moment'

import { Table, TableBody, TableHead, MDBTooltip, MDBIcon } from 'mdbreact'

import { formatDateTime } from '../utils/dateUtils'

const BarcodeImageIssueList = ({
  entries: { totalCount, edges },
  handleSelect,
  handleDelete
}) => (
  <>
    {totalCount ? <span>{totalCount} results</span> : null}
    <div>
      <Table>
        <TableHead textWhite color="unique-color-dark">
          <tr>
            <th>Barcode</th>
            <th>Reason</th>
            <th>Hits</th>
            <th>Products exist</th>
            <th style={{ textAlign: 'right' }}>Last seen</th>
            <th />
          </tr>
        </TableHead>

        <TableBody color="white">
          {edges.map(({ node }) => (
            <tr key={node.id}>
              <td
                className="small text-muted text-monospace"
                style={{ cursor: 'pointer' }}
                onClick={() => handleSelect(node)}
              >
                {node.barcode}
              </td>
              <td>
                {node.reason === 'POOR_SELECTION'
                  ? 'Poor search results'
                  : 'No image found'}
              </td>
              <td>{node.hits}</td>
              <td>
                <MDBIcon
                  icon={node.productsExist ? 'check' : 'times'}
                  fixed
                  className={
                    node.productsExist ? 'text-success' : 'text-danger'
                  }
                />
              </td>
              <td style={{ textAlign: 'right' }}>
                <MDBTooltip
                  placement="bottom"
                  tag="div"
                  tooltipContent={formatDateTime(node.lastSeen)}
                >
                  <Moment fromNow>{node.lastSeen}</Moment>
                </MDBTooltip>
              </td>
              <td style={{ textAlign: 'center' }}>
                <MDBIcon
                  icon="trash"
                  fixed
                  onClick={() => handleDelete(node.id)}
                  style={{ cursor: 'pointer' }}
                />
              </td>
            </tr>
          ))}
        </TableBody>
      </Table>
    </div>
  </>
)

BarcodeImageIssueList.propTypes = {
  entries: PropTypes.shape({
    totalCount: PropTypes.number,
    edges: PropTypes.array.isRequired
  }),
  handleSelect: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
}

export default BarcodeImageIssueList
