import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import moment from 'moment'

import { Table, TableBody, TableHead, MDBTooltip } from 'mdbreact'

const StoreList = ({ entries }) => (
  <div>
    <span>{entries.totalCount} results</span>
    <Table>
      <TableHead textWhite color="unique-color-dark">
        <tr>
          <th>Short ID</th>
          <th>Name</th>
          <th style={{ textAlign: 'right' }}>Product Count</th>
          <th style={{ textAlign: 'right' }}>Order Count</th>
          <th style={{ textAlign: 'right' }}>Sales Sum</th>
          <th>Owner</th>
          <th>Created</th>
        </tr>
      </TableHead>

      <TableBody color="white">
        {entries.edges.map(({ node: { id, shortId, name, productCount, orderCount, orderSum, createdAt, owner } }) => (
          <tr key={id}>
            <td className="small text-muted text-monospace">{shortId}</td>
            <td><Link to={`/stores/${id}`}>{name}</Link></td>
            <td style={{ textAlign: 'right' }}>{productCount}</td>
            <td style={{ textAlign: 'right' }}>{orderCount}</td>
            <td style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
              }).format(orderSum)}
            </td>
            <td>{owner.name} <span className="text-muted">({owner.email})</span></td>
            <td>
              <MDBTooltip
                placement="bottom"
                tag="div"
                tooltipContent={moment(createdAt).tz('Atlantic/Reykjavik').format('lll z')}>
                <Moment fromNow>{createdAt}</Moment>
              </MDBTooltip>
            </td>
          </tr>
        ))}
      </TableBody>
    </Table>
  </div>
)
StoreList.propTypes = {
  entries: PropTypes.object.isRequired
}

export default StoreList
