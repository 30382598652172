import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withFormik } from 'formik'
import gql from 'graphql-tag'
import * as yup from 'yup'
import classNames from 'classnames'

import { ApolloConsumer, compose, withApollo } from 'react-apollo'
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from 'mdbreact'

const QUERY = gql`
  query DownloadToken(
    $size: Int
    $text: Boolean
    $padding: Boolean
    $ids: String
    $claimBatchName: String
  ) {
    downloadToken {
      qrZip(size: $size, text: $text, padding: $padding, ids: $ids, claimBatchName: $claimBatchName)
    }
  }
`

const enhancer = withFormik({
  validationSchema: yup.object().shape({
    batchName: yup.string().required().label('Batch name'),
    size: yup.number(),
    text: yup.bool(),
    padding: yup.bool(),
    square: yup.bool(),
    url: yup.string()
  }),

  enableReinitialize: true,

  mapPropsToValues: props => {
    return {
      batchName: props.batchName,
      size: '',
      text: true,
      padding: false,
      square: false,
      url: ''
    }
  },

  handleSubmit: async (values, { props, setSubmitting, setFieldValue }) => {
    const query = await props.client.query({
      query: QUERY,
      variables: {
        size: values.size ? values.size : null,
        text: values.text,
        padding: values.padding,
        ids: '',
        claimBatchName: values.batchName
      }
    })

    setSubmitting(false)

    setFieldValue('url', query.data.downloadToken.qrZip)
  }
})

class ClaimQRModal extends Component {
  static propTypes = {
    batchName: PropTypes.string,
    onClose: PropTypes.func,
    show: PropTypes.bool.isRequired,

    touched: PropTypes.object,
    errors: PropTypes.object,
    values: PropTypes.object,
    status: PropTypes.string,
    isSubmitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setStatus: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired
  }

  toggle = () => {
    this.props.onClose()
  }

  render () {
    return (
      <ApolloConsumer>
        {client => (
          <MDBModal isOpen={this.props.show} toggle={this.toggle} size="lg">
            <form onSubmit={this.props.handleSubmit} noValidate>
              <MDBModalHeader toggle={this.toggle}>
                Export QR Codes
              </MDBModalHeader>
              <MDBModalBody>
                { !this.props.values.url
                  ? <React.Fragment>
                    <div className="form-group">
                      <label htmlFor="batchName">Batch Name</label>
                      <input
                        id="batchName"
                        name="batchName"
                        type="text"
                        value={this.props.values.batchName}
                        onBlur={this.props.handleBlur}
                        onChange={this.props.handleChange}
                        className={classNames({
                          'form-control': true,
                          'is-invalid':
                        this.props.status === 'submitted' &&
                        this.props.errors.batchName
                        })}
                      />
                      {this.props.status === 'submitted' &&
                    this.props.errors.batchName && (
                        <div className="invalid-feedback d-block">
                          {this.props.errors.batchName}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="size">Size (pixels)</label>
                      <input
                        id="size"
                        name="size"
                        type="number"
                        value={this.props.values.size}
                        onBlur={this.props.handleBlur}
                        onChange={this.props.handleChange}
                        className={classNames({
                          'form-control': true,
                          'is-invalid':
                        this.props.status === 'submitted' &&
                        this.props.errors.size
                        })}
                      />
                      <small className="form-text text-muted">Leave blank for default size.</small>
                      {this.props.status === 'submitted' &&
                    this.props.errors.size && (
                        <div className="invalid-feedback d-block">
                          {this.props.errors.size}
                        </div>
                      )}
                    </div>

                    <div className="form-group form-check">
                      <input
                        id="text"
                        name="text"
                        type="checkbox"
                        checked={this.props.values.text}
                        onBlur={this.props.handleBlur}
                        onChange={this.props.handleChange}
                        className={classNames({
                          'form-check-input': true,
                          'is-invalid':
                            this.props.status === 'submitted' &&
                            this.props.errors.text
                        })}
                      />
                      <label className="form-check-label" htmlFor="text">With store ID</label>
                      {this.props.status === 'submitted' &&
                    this.props.errors.text && (
                        <div className="invalid-feedback d-block">
                          {this.props.errors.text}
                        </div>
                      )}
                    </div>

                    <div className="form-group form-check">
                      <input
                        id="padding"
                        name="padding"
                        type="checkbox"
                        checked={this.props.values.padding}
                        onBlur={this.props.handleBlur}
                        onChange={this.props.handleChange}
                        className={classNames({
                          'form-check-input': true,
                          'is-invalid':
                            this.props.status === 'submitted' &&
                            this.props.errors.padding
                        })}
                      />
                      <label className="form-check-label" htmlFor="padding">With extra padding</label>
                      {this.props.status === 'submitted' &&
                    this.props.errors.padding && (
                        <div className="invalid-feedback d-block">
                          {this.props.errors.padding}
                        </div>
                      )}
                    </div>

                    <div className="form-group form-check">
                      <input
                        id="square"
                        name="square"
                        type="checkbox"
                        checked={this.props.values.square}
                        onBlur={this.props.handleBlur}
                        onChange={this.props.handleChange}
                        className={classNames({
                          'form-check-input': true,
                          'is-invalid':
                            this.props.status === 'submitted' &&
                            this.props.errors.square
                        })}
                      />
                      <label className="form-check-label" htmlFor="square">Square output image</label>
                      {this.props.status === 'submitted' &&
                    this.props.errors.square && (
                        <div className="invalid-feedback d-block">
                          {this.props.errors.square}
                        </div>
                      )}
                    </div>
                  </React.Fragment>

                  : <div>
                    <h4>Download File</h4>
                    <a href={this.props.values.url} rel="noopener noreferrer" target="_blank">Click here to download the ZIP file</a>
                  </div>
                }
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  color="primary"
                  disabled={this.props.isSubmitting}
                  type={ !this.props.values.url ? 'submit' : 'button' }
                  onClick={() => { !this.props.values.url ? this.props.setStatus('submitted') : this.props.onClose() }}
                >
                  { !this.props.values.url ? 'Export' : 'Close' }
                </MDBBtn>
              </MDBModalFooter>
            </form>
          </MDBModal>
        )}
      </ApolloConsumer>
    )
  }
}

export default compose(
  withApollo,
  enhancer
)(ClaimQRModal)
