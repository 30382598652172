import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import 'prismjs'
import { PrismCode } from 'react-prism'
import Moment from 'react-moment'

import OrderStatusModal from '../components/modals/OrderStatusModal'

import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  Table,
  TableBody,
  TableHead,
  Button
} from 'mdbreact'

import gql from 'graphql-tag'
import { Query } from 'react-apollo'

const GET_ORDER = gql`
  query Order($id: ID!) {
    order(id: $id) {
      id
      orderCode
      orderStatus
      orderDisbursed
      payeeMerchantId
      createdAt
      currency
      subtotal
      tax
      totalAmount
      isShipping
      shippingAmount
      shippingCurrency
      paypalTransactionFeeValue
      paypalTransactionFeeCurrency
      paypalPartnerFeeValue
      paypalPartnerFeeCurrency
      store {
        id
        shortId
        name
        coverPhoto
      }
      items {
        id
        name
        productCode
        quantity
        price
        vatPercent
      }
      errors {
        id
        action
        timestamp
        body
        error
      }
    }
  }
`

export default class OrderPage extends Component {
  static propTypes = {
    match: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      orderStatusModal: false,
      orderStatusModalId: ''
    }
  }

  render () {
    return (
      <Query query={GET_ORDER} variables={{ id: this.props.match.params.id }}>
        {({ loading, error, data, fetchMore }) => {
          if (loading) return 'Loading...'
          if (error) return `Error! ${error.message}`
          return (
            <React.Fragment>
              <MDBBreadcrumb color="white">
                <MDBBreadcrumbItem>
                  <Link to="/">Home</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem>
                  <Link to="/orders">Orders</Link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>
                  {data.order.orderCode}
                </MDBBreadcrumbItem>
              </MDBBreadcrumb>
              <MDBRow className="mb-4">
                <MDBCol size="4">
                  <MDBCard>
                    <MDBCardImage
                      className="img-fluid"
                      src={data.order.store.coverPhoto}
                      waves
                    />
                    <MDBCardBody>
                      <React.Fragment>
                        <h1>{data.order.store.name}</h1>
                      </React.Fragment>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
                <MDBCol size="8">
                  <MDBCard>
                    <MDBCardBody>
                      <React.Fragment>
                        <h2 className="mb-4">Order Details</h2>
                        <Table borderless hover>
                          <TableBody>
                            <tr>
                              <th>Order status</th>
                              <td>{data.order.orderStatus}</td>
                            </tr>
                            <tr>
                              <th>Order disbursed</th>
                              <td>{data.order.orderDisbursed ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                              <th>Order number</th>
                              <td>{data.order.orderCode}</td>
                            </tr>
                            <tr>
                              <th>Created at</th>
                              <td><Moment tz="Atlantic/Reykjavik" date={data.order.createdAt} format="lll z" /> <small className="text-muted">(<Moment fromNow>{data.order.createdAt}</Moment>)</small></td>
                            </tr>
                          </TableBody>
                        </Table>
                        <Button onClick={() => this.setState({ orderStatusModalId: data.order.id, orderStatusModal: true })}>
                          PayPal Order Status
                        </Button>
                      </React.Fragment>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-4">
                <MDBCol size="12">
                  <MDBCard>
                    <MDBCardBody>
                      <h3 className="mb-4">Order Items</h3>
                      <Table hover>
                        <TableHead textWhite color="unique-color-dark">
                          <tr>
                            <th>Product #</th>
                            <th>Product name</th>
                            <th style={{ textAlign: 'right' }}>Quantity</th>
                            {/* <th style={{ textAlign: 'right' }}>Item tax</th> */}
                            <th style={{ textAlign: 'right' }}>Item price</th>
                          </tr>
                        </TableHead>
                        <TableBody color="white">
                          {data.order.items.map(item => (
                            <tr key={item.id}>
                              <td className="small text-muted text-monospace">{item.productCode}</td>
                              <td>{ item.name }</td>
                              <td style={{ textAlign: 'right' }}>{ item.quantity }</td>
                              { /* <td style={{ textAlign: 'right' }}>
                                {item.vatPercent} %
                              </td> */}
                              <td style={{ textAlign: 'right' }}>
                                {new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: item.currency || 'USD'
                                }).format(item.price)}
                              </td>
                            </tr>
                          ))}
                        </TableBody>
                      </Table>
                      <MDBRow>
                        <MDBCol size="3" className="offset-9">
                          <Table borderless>
                            <TableBody>
                              <tr>
                                <td>Subtotal</td>
                                <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: data.order.currency || 'USD'
                                }).format(data.order.subtotal)}</td>
                              </tr><tr>
                                <td>Shipping:</td>
                                <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: data.order.shippingCurrency || 'USD'
                                }).format(data.order.shippingAmount)}</td>
                              </tr><tr>
                                <td>Tax:</td>
                                <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: data.order.currency || 'USD'
                                }).format(data.order.tax)}</td>
                              </tr><tr>
                                <td>Total:</td>
                                <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: data.order.currency || 'USD'
                                }).format(data.order.totalAmount)}</td>
                              </tr><tr>
                                <td>PayPal fee:</td>
                                <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: data.order.paypalTransactionFeeCurrency || 'USD'
                                }).format(data.order.paypalTransactionFeeValue)}</td>
                              </tr><tr>
                                <td>Hausmart fee:</td>
                                <td style={{ textAlign: 'right' }}>{new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: data.order.paypalPartnerFeeCurrency || 'USD'
                                }).format(data.order.paypalPartnerFeeValue)}</td>
                              </tr>
                            </TableBody>
                          </Table>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
              { data.order.errors.length > 0 &&
                <MDBRow>
                  <MDBCol size="12">
                    <MDBCard>
                      <MDBCardBody>
                        <h3 className="mb-4">Order Errors</h3>
                        <Table hover>
                          <TableHead textWhite color="unique-color-dark">
                            <tr>
                              <th>Timestamp</th>
                              <th>Action</th>
                              <th>Error</th>
                            </tr>
                          </TableHead>
                          <TableBody color="white">
                            {data.order.errors.map(err => (
                              <tr key={err.id}>
                                <td className="small text-muted text-monospace"><Moment tz="Atlantic/Reykjavik" date={err.timestamp} format="lll z" /></td>
                                <td>{ err.action }</td>
                                <td>
                                  { err.error &&
                                    <PrismCode component="pre" className="language-json">{ JSON.stringify(JSON.parse(err.error), null, 2) }</PrismCode>

                                  }
                                </td>
                              </tr>
                            ))}
                          </TableBody>
                        </Table>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              }
              <OrderStatusModal
                show={this.state.orderStatusModal}
                orderId={this.state.orderStatusModalId}
                onClose={() => this.setState({ orderStatusModal: false })}
              />
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
}
