import React from 'react'
import gql from 'graphql-tag'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import AsyncSelect from 'react-select/lib/Async'
import { components as selectComponents } from 'react-select'
import { MDBIcon } from 'mdbreact'

const SEARCH_TEMPLATE_STORES = gql`
  query TemplateStoreSearch($search: String) {
    templateStores(
      first: 15
      search: $search
      isClaimable: true
      isEnabled: true
      isDeleted: false
    ) {
      edges {
        cursor
        node {
          id
          name
          isTaxEnabled
          isStockEnabled
          isShippingEnabled
          isTagsEnabled
          isPrivate
          isIndexable
        }
      }
    }
  }
`

const idNameOptions = (query, data, client, inputValue, callback) => {
  client
    .query({
      query: query,
      variables: {
        search: inputValue
      }
    })
    .then(result => {
      callback(
        result.data[data].edges.map(edge => ({
          value: edge.node.id,
          label: edge.node.name,
          obj: edge.node
        }))
      )
    })
}

const TemplateStoreOption = props => {
  const featureIcon = value =>
    value ? (
      <MDBIcon icon="check" fixed className="text-success" />
    ) : (
      <MDBIcon icon="remove" fixed className="text-danger" />
    )

  return (
    <div>
      <selectComponents.Option {...props}>
        {props.children}
        <div className="pl-2 pt-1 small">
          {featureIcon(props.data.obj.isTaxEnabled)} Tax
          <br />
          {featureIcon(props.data.obj.isStockEnabled)} Inventory
          <br />
          {featureIcon(props.data.obj.isShippingEnabled)} Shipping
          <br />
          {featureIcon(props.data.obj.isTagsEnabled)} Tags
          <br />
          {featureIcon(props.data.obj.isPrivate)} Private
        </div>
      </selectComponents.Option>
    </div>
  )
}

const TemplateStoreSelect = ({
  client,
  value,
  errors,
  status,
  onChange,
  handleBlur
}) => {
  return (
    <>
      <label htmlFor="templateStoreId">Template Store</label>
      <AsyncSelect
        id="templateStoreId"
        name="templateStoreId"
        components={{ Option: TemplateStoreOption }}
        defaultOptions
        cacheOptions
        loadOptions={debounce(
          (inputValue, callback) =>
            idNameOptions(
              SEARCH_TEMPLATE_STORES,
              'templateStores',
              client,
              inputValue,
              callback
            ),
          500
        )}
        value={value}
        onBlur={handleBlur}
        onChange={onChange}
        className={classNames({
          'is-invalid': status === 'submitted' && errors.shortId
        })}
        required
      />
      {status === 'submitted' && errors.templateStoreId && (
        <div className="invalid-feedback d-block">{errors.templateStoreId}</div>
      )}
    </>
  )
}

export default TemplateStoreSelect
