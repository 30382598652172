import React from 'react'
import PropTypes from 'prop-types'

import Moment from 'react-moment'
import Select from 'react-select'
import momentPropTypes from 'react-moment-proptypes'
import { Table, TableBody, TableHead, MDBTooltip, MDBIcon } from 'mdbreact'
import 'react-dates/initialize'

import TableFilter from './elements/TableFilter'
import { FILTERS } from '../graphql/tableFilters'
import { formatDateTime } from '../utils/dateUtils'
import DateRangePickerWrapper from './elements/DateRangeWrapper'
import curry from 'lodash/curry'

const LoginIcon = ({ method }) => {
  if (!method) return null
  let lmethod = method.toLowerCase()

  return (
    <MDBTooltip placement="bottom" tag="div" tooltipContent={lmethod}>
      <MDBIcon
        icon={lmethod === 'password' ? 'key' : lmethod}
        style={{ color: 'black' }}
      />
    </MDBTooltip>
  )
}
LoginIcon.propTypes = {
  method: PropTypes.oneOf([
    'google',
    'GOOGLE',
    'facebook',
    'FACEBOOK',
    'password',
    'PASSWORD'
  ])
}

const FormattedTime = ({ time }) => {
  if (!time) return null
  return (
    <MDBTooltip
      placement="bottom"
      tag="div"
      tooltipContent={formatDateTime(time)}
    >
      <Moment fromNow>{time}</Moment>
    </MDBTooltip>
  )
}
FormattedTime.propTypes = {
  time: PropTypes.string
}

const CheckMark = ({ isChecked, toolTip }) => {
  let icon = (
    <MDBIcon
      icon={isChecked ? 'check' : 'times'}
      fixed
      className={isChecked ? 'text-success' : 'text-danger'}
    />
  )

  return toolTip ? (
    <MDBTooltip placement="bottom" tag="div" tooltipContent={toolTip}>
      {icon}
    </MDBTooltip>
  ) : (
    icon
  )
}
CheckMark.propTypes = {
  isChecked: PropTypes.bool,
  toolTip: PropTypes.string
}

const OrderIcon = (currentOrder, fieldId) => {
  let iconName
  switch (currentOrder.indexOf(fieldId)) {
    case 0:
      iconName = 'sort-desc'
      break
    case 1:
      iconName = 'sort-asc'
      break
    default:
      iconName = 'sort'
  }
  return <MDBIcon icon={iconName} />
}

const BooleanFilter = ({ name, defaultValue, onChange, placeholder }) => {
  let icons = {
    true: <MDBIcon icon="check" className="text-success" />,
    false: <MDBIcon icon="times" className="text-danger" />
  }

  return (
    <Select
      isClearable={true}
      isSearchable={false}
      name={name}
      placeholder={placeholder}
      options={[
        {
          value: 'true',
          label: icons.true
        },
        {
          value: 'false',
          label: icons.false
        }
      ]}
      value={
        defaultValue !== null
          ? {
            value: defaultValue.toString(),
            label: icons[defaultValue.toString()]
          }
          : null
      }
      onChange={onChange}
    />
  )
}
BooleanFilter.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
}

const FunnelReportList = ({
  items,
  emailFilter,
  campaignFilter,
  shortIdFilter,
  appInstalledFilter,
  lastLoginWithFilter,
  paypalEmailFilter,
  isRenamedFilter,
  isClaimedFilter,
  hasProductsFilter,
  orderBy,
  updateOrderBy,
  updateFilter,
  updateDateFilter,
  showFilters,
  lastLoginAfter,
  lastLoginBefore
}) => {
  let orderIcon = curry(OrderIcon)(orderBy)

  return (
    <div>
      <span>{items.totalCount} results</span>
      <Table>
        <TableHead textWhite color="unique-color-dark">
          <tr className="sortable-header">
            <th onClick={() => updateOrderBy('requestEmail')}>
              {orderIcon('requestEmail')} Request Email
            </th>
            <th onClick={() => updateOrderBy('campaign')}>
              {orderIcon('campaign')} Campaign
            </th>
            <th onClick={() => updateOrderBy('shortId')}>
              {orderIcon('shortId')} Store ID
            </th>
            <th onClick={() => updateOrderBy('isClaimed')}>
              {orderIcon('isClaimed')} Claimed
            </th>
            <th
              onClick={() => updateOrderBy('appInstalled')}
              className="text-center"
            >
              {orderIcon('appInstalled')} Installed App
            </th>
            <th
              onClick={() => updateOrderBy('lastLoginWith')}
              className="text-center"
            >
              {orderIcon('lastLoginWith')} Last login with
            </th>
            <th onClick={() => updateOrderBy('lastLoginAt')}>
              {orderIcon('lastLoginAt')} Last login
            </th>
            <th onClick={() => updateOrderBy('paypalEmail')}>
              {orderIcon('paypalEmail')} PayPal email
            </th>
            <th
              onClick={() => updateOrderBy('isRenamed')}
              className="text-center"
            >
              {orderIcon('isRenamed')} Renamed
            </th>
            <th
              onClick={() => updateOrderBy('productCount')}
              className="text-right"
            >
              {orderIcon('productCount')} Product count
            </th>
          </tr>
          {showFilters && (
            <tr className="table-filters">
              <th>
                <TableFilter
                  filter={FILTERS.email}
                  defaultInputValue={emailFilter}
                  onChange={value =>
                    updateFilter({
                      filterName: 'emailFilter',
                      value: value ? value.label : value
                    })
                  }
                />
              </th>
              <th>
                <TableFilter
                  filter={FILTERS.campaign}
                  defaultInputValue={campaignFilter}
                  onChange={value =>
                    updateFilter({
                      filterName: 'campaignFilter',
                      value: value ? value.label : value
                    })
                  }
                />
              </th>
              <th>
                <TableFilter
                  filter={FILTERS.shortId}
                  defaultInputValue={shortIdFilter}
                  onChange={value =>
                    updateFilter({
                      filterName: 'shortIdFilter',
                      value: value ? value.label : value
                    })
                  }
                />
              </th>
              <th>
                <BooleanFilter
                  name="isClaimed"
                  defaultValue={isClaimedFilter}
                  onChange={value =>
                    updateFilter({
                      filterName: 'isClaimedFilter',
                      value: value ? value.value === 'true' : value
                    })
                  }
                />
              </th>
              <th>
                <BooleanFilter
                  name="appInstalled"
                  defaultValue={appInstalledFilter}
                  onChange={value =>
                    updateFilter({
                      filterName: 'appInstalledFilter',
                      value: value ? value.value === 'true' : value
                    })
                  }
                />
              </th>
              <th>
                <Select
                  isClearable={true}
                  isSearchable={false}
                  name="lastLoginWith"
                  options={[
                    {
                      value: 'password',
                      label: <LoginIcon method="password" />
                    },
                    {
                      value: 'google',
                      label: <LoginIcon method="google" />
                    },
                    {
                      value: 'facebook',
                      label: <LoginIcon method="facebook" />
                    }
                  ]}
                  value={
                    lastLoginWithFilter !== null
                      ? {
                        value: lastLoginWithFilter,
                        label: <LoginIcon method={lastLoginWithFilter} />
                      }
                      : ''
                  }
                  onChange={value =>
                    updateFilter({
                      filterName: 'lastLoginWithFilter',
                      value: value ? value.value : value
                    })
                  }
                />
              </th>
              <th>
                <DateRangePickerWrapper
                  initialStartDate={lastLoginAfter}
                  initialEndDate={lastLoginBefore}
                  onChange={({ startDate, endDate }) =>
                    updateDateFilter('lastLogin', endDate, startDate)
                  }
                />
              </th>
              <th>
                <TableFilter
                  filter={FILTERS.paypalEmail}
                  defaultInputValue={paypalEmailFilter}
                  onChange={value =>
                    updateFilter({
                      filterName: 'paypalEmailFilter',
                      value: value ? value.label : value
                    })
                  }
                />
              </th>
              <th>
                <BooleanFilter
                  name="isRenamed"
                  defaultValue={isRenamedFilter}
                  onChange={value =>
                    updateFilter({
                      filterName: 'isRenamedFilter',
                      value: value ? value.value === 'true' : value
                    })
                  }
                />
              </th>
              <th>
                <BooleanFilter
                  name="hasProducts"
                  defaultValue={hasProductsFilter}
                  placeholder=">0?"
                  onChange={value =>
                    updateFilter({
                      filterName: 'hasProductsFilter',
                      value: value ? value.value === 'true' : value
                    })
                  }
                />
              </th>
            </tr>
          )}
        </TableHead>

        <TableBody color="white">
          {items.edges.map(
            (
              {
                node: {
                  shortId,
                  isRenamed,
                  isClaimed,
                  requestEmail,
                  claimDeviceId,
                  campaign,
                  store,
                  templateStore
                }
              },
              i
            ) => (
              <tr key={i}>
                <td className="small">
                  {(store && store.owner && (
                    <MDBTooltip
                      placement="bottom"
                      tag="div"
                      tooltipContent={`${formatDateTime(store.owner.createdAt)} Store owner: ${store.owner.email}`}
                    >
                      {requestEmail || 'none'}
                    </MDBTooltip>
                  )) ||
                    requestEmail}
                </td>
                <td className="text-monospace small">
                  {campaign && (
                    <MDBTooltip
                      placement="bottom"
                      tag="div"
                      tooltipContent={campaign.name}
                    >
                      {campaign.code}
                    </MDBTooltip>
                  )}
                </td>
                <td className="text-monospace small">{shortId}</td>
                <td className="text-center small">
                  <CheckMark
                    toolTip={store && formatDateTime(store.createdAt)}
                    isChecked={!!isClaimed}
                  />
                </td>
                <td className="text-center small">
                  <CheckMark
                    toolTip={claimDeviceId && `Device ID: ${claimDeviceId}`}
                    isChecked={!!claimDeviceId}
                  />
                </td>
                <td className="text-center small">
                  <LoginIcon
                    method={store && store.owner && store.owner.lastLoginWith}
                  />
                </td>
                <td className="small">
                  <FormattedTime
                    time={store && store.owner && store.owner.lastLogin}
                  />
                </td>
                <td className="small">
                  {store && store.owner && store.owner.email}
                </td>
                <td className="text-center small">
                  <CheckMark
                    toolTip={isRenamed ? store.name : templateStore.name}
                    isChecked={isRenamed}
                  />
                </td>
                <td className="text-right small">
                  {store && store.productCount}
                </td>
              </tr>
            )
          )}
        </TableBody>
      </Table>
    </div>
  )
}

FunnelReportList.propTypes = {
  items: PropTypes.shape({
    totalCount: PropTypes.number.isRequired,
    edges: PropTypes.array.isRequired
  }),
  emailFilter: PropTypes.string,
  campaignFilter: PropTypes.string,
  shortIdFilter: PropTypes.string,
  appInstalledFilter: PropTypes.bool,
  lastLoginWithFilter: PropTypes.string,
  paypalEmailFilter: PropTypes.string,
  isRenamedFilter: PropTypes.bool,
  isClaimedFilter: PropTypes.bool,
  hasProductsFilter: PropTypes.bool,
  orderBy: PropTypes.string.isRequired,
  updateOrderBy: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  updateDateFilter: PropTypes.func,
  setClaimedDatePickerFocus: PropTypes.func,
  showFilters: PropTypes.bool,
  lastLoginAfter: momentPropTypes.momentObj,
  lastLoginBefore: momentPropTypes.momentObj
}

export default FunnelReportList
