import React, { Component } from 'react'

import { Card, CardBody, Button, MDBIcon } from 'mdbreact'

import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import ClaimList from '../components/ClaimList'
import ClaimCreateModal from '../components/modals/ClaimCreateModal'
import ClaimQRModal from '../components/modals/ClaimQRModal'
import Pagination from '../components/Pagination'

const PAGE_SIZE = 10

export const getClaimsListQuery = gql`
  query Claims($first: Int, $after: String, $search: String) {
    claims: templateStoreClaims(first: $first, after: $after, search: $search) {
      edges {
        cursor
        node {
          id
          shortId
          batchName
          createdAt
          isClaimed
          claimIsActive
          store {
            id
            name
            webUrl(notify: false)
          }
          templateStore {
            id
            name
          }
          campaign {
            id
            name
          }
          createdBy {
            id
            name
          }
        }
      }
      totalCount
    }
  }
`

export class ClaimListPage extends Component {
  static propTypes = {}

  constructor (props) {
    super(props)
    this.state = {
      pageSize: PAGE_SIZE,
      currentPage: 1,
      typing: false,
      typingTimeout: 0,
      search: '',
      currentSearch: '',
      claimCreateModal: false,
      claimCreateBatchModal: false,
      claimQRModal: false,
      claimQRModalBatchName: ''
    }
  }

  changePageSize = event => {
    this.setState({ pageSize: event.target.value, currentPage: 1 })
  }

  typeSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout)
    }

    this.setState({
      search: event.target.value,
      typing: true,
      typingTimeout: setTimeout(() => {
        this.search()
      }, 500)
    })
  }

  search = () => {
    this.setState({
      currentSearch: this.state.search,
      currentPage: 1,
      typing: false
    })
  }

  qrModal = (batchName) => {
    this.setState({ claimQRModalBatchName: batchName, claimQRModal: true })
  }

  render () {
    return (
      <Card>
        <CardBody>
          <div className="pull-right">
            <Button onClick={() => this.setState({ claimCreateModal: true })}>
              Create a claim
            </Button>
            <Button onClick={() => this.setState({ claimCreateBatchModal: true })}>
              Create a batch
            </Button>
          </div>
          <h1>Claim Search</h1>

          <div className="input-group md-form form-sm form-1 pl-0">
            <div className="input-group-prepend">
              <span className="input-group-text lighten-3" id="basic-text1">
                <MDBIcon className="text-white" icon="search" />
              </span>
            </div>
            <input
              className="form-control my-0 py-1"
              type="text"
              placeholder="Search"
              aria-label="Search"
              value={this.state.search}
              onChange={this.typeSearch}
            />
          </div>

          <Query
            query={getClaimsListQuery}
            variables={{ first: this.state.pageSize, search: this.state.currentSearch }}
            fetchPolicy="cache-and-network"
          >
            {({ loading, error, data, fetchMore, refetch }) => {
              if (loading) return 'Loading...'
              if (error) return `Error! ${error.message}`

              return (
                <React.Fragment>
                  <div className="pull-right">
                    <select value={this.state.pageSize} onChange={this.changePageSize} className="browser-default custom-select form-control-sm">
                      <option value="10">Show 10 results</option>
                      <option value="25">Show 25 results</option>
                      <option value="50">Show 50 results</option>
                      <option value="100">Show 100 results</option>
                      <option value="250">Show 250 results</option>
                    </select>
                  </div>
                  <Pagination
                    pageSize={PAGE_SIZE}
                    totalCount={data.claims.totalCount || 0}
                    currentPage={this.state.currentPage}
                    fetchMore={fetchMore}
                    setCurrentPage={page => this.setState({ currentPage: page })} >
                    <ClaimList entries={data.claims || []} qrModal={this.qrModal} />
                  </Pagination>
                  <ClaimCreateModal
                    show={this.state.claimCreateModal}
                    onClose={() => this.setState({ claimCreateModal: false })}
                    onCreated={() => {
                      refetch()
                      this.setState({ claimCreateModal: false })
                    }}
                  />
                  <ClaimCreateModal
                    show={this.state.claimCreateBatchModal}
                    batch={true}
                    onClose={() => this.setState({ claimCreateBatchModal: false })}
                    onCreated={() => {
                      refetch()
                      this.setState({ claimCreateBatchModal: false })
                    }}
                  />
                  <ClaimQRModal
                    show={this.state.claimQRModal}
                    batchName={this.state.claimQRModalBatchName}
                    onClose={() => this.setState({ claimQRModal: false, claimQRModalBatchName: '' })}
                  />
                </React.Fragment>
              )
            }}
          </Query>
        </CardBody>
      </Card>
    )
  }
}

export default ClaimListPage
