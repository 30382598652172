import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'

import { ApolloClient } from 'apollo-client'
import { ApolloProvider } from 'react-apollo'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

import 'moment-timezone'

import 'font-awesome/css/font-awesome.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
// import 'mdbreact/dist/css/mdb.css'

import Login from './containers/Login'
import TopNavigation from './components/TopNavigation'
import SideNavigation from './components/SideNavigation'
import Home from './containers/Home'
import StorePage from './containers/StorePage'
import StoreListPage from './containers/StoreListPage'
import OrderListPage from './containers/OrderListPage'
import OrderPage from './containers/OrderPage'
import CampaignListPage from './containers/CampaignListPage'
import ClaimListPage from './containers/ClaimListPage'
import FunnelReportPage from './containers/FunnelReportPage'
import POPOrderListPage from './containers/POPOrderListPage'
import BarcodeImagesPage from './containers/BarcodeImagesPage'
import UserListPage from './containers/UserListPage'
import BannedWordListPage from './containers/BannedWordListPage'

import auth from './utils/auth'
import config from './config'
import { AuthLink } from './utils/auth-link'

import 'react-dates/lib/css/_datepicker.css'
import './style/prism.css'
import './style/mdb.scss'
import './style/main.scss'
import './App.css'
import './Authenticated.scss'

const httpLink = createHttpLink({
  uri: config.API_URL
})

const authLink = new AuthLink()
// const authLink = setContext((_, { headers }) => {
//   if (_.operationName === 'TokenAuth') {
//     return {
//       headers: headers
//     }
//   }

//   // get the authentication token from local storage if it exists
//   const token = auth.getToken()
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `JWT ${token}` : ''
//     }
//   }
// })

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

authLink.injectClient(client)

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated() ? (
        <div className="flexible-content">
          <TopNavigation />
          <SideNavigation />
          <main id="content" className="p-5">
            <Component {...props} />
          </main>
        </div>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: props.location
            }
          }}
        />
      )
    }
  />
)

class App extends Component {
  render () {
    return (
      <ApolloProvider client={client}>
        <Router>
          <div className="App-container">
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/" component={Home} />

            <PrivateRoute
              exact
              path="/stores"
              component={StoreListPage}
              name="stores"
            />
            <PrivateRoute
              exact
              path="/stores/:id"
              component={StorePage}
              name="store"
            />

            <PrivateRoute
              exact
              path="/orders"
              component={OrderListPage}
              name="orders"
            />
            <PrivateRoute
              exact
              path="/orders/:id"
              component={OrderPage}
              name="order"
            />

            <PrivateRoute
              exact
              path="/campaigns"
              component={CampaignListPage}
              name="campaigns"
            />

            <PrivateRoute
              exact
              path="/claims"
              component={ClaimListPage}
              name="claims"
            />

            <PrivateRoute
              exact
              path="/reports/funnel"
              component={FunnelReportPage}
              name="funnelReport"
            />

            <PrivateRoute
              exact
              path="/pop"
              component={POPOrderListPage}
              name="poporders"
            />

            <PrivateRoute
              exact
              path="/barcodeimages"
              component={BarcodeImagesPage}
              name="barcodeImages"
            />

            <PrivateRoute
              exact
              path="/users"
              component={UserListPage}
              name="users"
            />

            <PrivateRoute
              exact
              path="/bannedwords"
              component={BannedWordListPage}
              name="bannedwords"
            />
          </div>
        </Router>
      </ApolloProvider>
    )
  }
}

export default App
