import React from 'react'
import PropTypes from 'prop-types'
import 'prismjs'
import { PrismCode } from 'react-prism'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn
} from 'mdbreact'

const QUERY = gql`
  query Order($id: ID!) {
    order(id: $id) {
      id
      paypalOrderStatus
    }
  }
`

export default class OrderStatusModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    orderId: PropTypes.string.isRequired
  }
  render () {
    return (
      <MDBModal isOpen={this.props.show} toggle={this.props.onClose} size="lg">
        <MDBModalHeader toggle={this.props.onClose}>
            PayPal Order Status
        </MDBModalHeader>
        <MDBModalBody>
          <Query query={QUERY} variables={{ id: this.props.orderId }}>
            {({ loading, error, data, fetchMore }) => {
              if (loading) return 'Loading...'
              if (error) return `Error! ${error.message}`
              return (
                <PrismCode component="pre" className="language-json">{ JSON.stringify(JSON.parse(data.order.paypalOrderStatus), null, 2) }</PrismCode>
              )
            }}
          </Query>

        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color="primary"
            type="button"
            onClick={() => this.props.onClose()}
          >
              Ok
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}
