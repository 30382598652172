import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody, TableHead, MDBIcon } from 'mdbreact'

export default class ProductList extends Component {
  static propTypes = {
    entries: PropTypes.object.isRequired
  }

  render () {
    let { entries } = this.props
    return (
      <div>
        <Table>
          <TableHead textWhite color="unique-color-dark">
            <tr>
              <th>Name</th>
              <th>Price</th>
              <th>Tag</th>
              <th>Description</th>
              <th>Quantity</th>
              <th>Barcode</th>
              <th>Enabled</th>
            </tr>
          </TableHead>

          <TableBody color="white">
            {entries.edges.map(({ node }) => (
              <tr key={node.id}>
                <td>{node.name}</td>
                <td>{node.price}</td>
                <td>{node.tag && node.tag.name}</td>
                <td>{node.description}</td>
                <td>{node.stockQuantity}</td>
                <td>{node.scannedBarcode}</td>
                <td>
                  <MDBIcon
                    icon={node.isEnabled ? 'check' : 'times'}
                    className={node.isEnabled ? 'text-success' : 'text-danger'}
                  />
                </td>
              </tr>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }
}
