import React from 'react'
import PropTypes from 'prop-types'
import { compose, withApollo } from 'react-apollo'
import AsyncCreatable from 'react-select/lib/AsyncCreatable'
import debounce from 'lodash/debounce'
import { tableFilterPropType } from '../../graphql/tableFilters'

const idNameOptions = (filter, client, inputValue, callback) => {
  let { query, name, getOptions } = filter
  client
    .query({
      query: query,
      variables: {
        search: inputValue
      }
    })
    .then(result => {
      callback(result.data[name].edges.map(getOptions))
    })
}

const TableFilter = ({ filter, defaultInputValue, onChange, client }) => {
  return (
    <AsyncCreatable
      defaultInputValue={defaultInputValue || ''}
      cacheOptions
      loadOptions={debounce(
        (inputValue, callback) =>
          idNameOptions(filter, client, inputValue, callback),
        500
      )}
      onChange={onChange}
      createOptionPosition="first"
      formatCreateLabel={val => `Search for ${val}`}
      isClearable
      allowCreateWhileLoading
      styles={{ option: provided => ({ ...provided, color: 'black' }) }}
    />
  )
}
TableFilter.propTypes = {
  filter: tableFilterPropType,
  defaultInputValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired
}

export default compose(withApollo)(TableFilter)
