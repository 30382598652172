import gql from 'graphql-tag'

import jwtDecode from 'jwt-decode'
import store from 'store'
import moment from 'moment'

const REFRESH_MUTATION = gql`
  mutation RefreshToken($refresh: String!) {
    refreshToken(input: { refresh: $refresh }) {
      token
      refresh
      errors {
        field
        messages
      }
    }
  }
`

class Auth {
  constructor () {
    this.idToken = 'team_token'
    this.refreshToken = 'team_refresh'
  }

  getToken () {
    return store.get(this.idToken)
  }

  getRefresh () {
    return store.get(this.refreshToken)
  }

  isAuthenticated () {
    const token = this.getToken()

    if (!token) {
      return false
    }

    try {
      const decoded = jwtDecode(token)
      const now = moment().toDate().valueOf() / 1000

      if (decoded.exp < now) {
        return false
      }
    } catch {
      return false
    }

    return true
  }

  expiresWithin (minutes) {
    const token = this.getToken()

    if (!token) {
      return false
    }

    try {
      const decoded = jwtDecode(token)
      const then = moment().add(minutes, 'minutes').toDate().valueOf() / 1000

      if (decoded.exp < then) {
        return true
      }
    } catch {
      return false
    }

    return false
  }

  login (token, refresh) {
    store.set(this.idToken, token)
    store.set(this.refreshToken, refresh)
  }

  logout () {
    store.remove(this.idToken)
    store.remove(this.refreshToken)
  }

  async refresh (client) {
    return client.mutate({
      mutation: REFRESH_MUTATION,
      variables: {
        refresh: this.getRefresh()
      }
    })
  }
}

const auth = new Auth()

export default auth
