import React, { Component } from 'react'

import { Card, CardBody, Button, MDBIcon } from 'mdbreact'

import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import BannedWordList from '../components/BannedWordList'
import Pagination from '../components/Pagination'
import BannedWordModal from '../components/modals/BannedWordModal'

const PAGE_SIZE = 10

const GET_WORDS = gql`
query BannedWords($first: Int, $after: String, $search: String) {
  bannedWords(first: $first, after: $after, word: $search) {
    totalCount
    edges {
      node {
        id
        word        
        type
        description
        createdAt
      }
    }
  }
}
`

const DELETE_BANNED_WORD = gql`
mutation DeleteBannedWord($id: ID!) {
  deleteBannedWord(input: { id: $id }) {
    success
  }
}
`

export class BannedWordListPage extends Component {
  static propTypes = {}

  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      search: '',
      currentSearch: '',
      typingTimeout: 0,
      showBannedWordModal: false
    }
  }

  typeSearch (value) {
    let { typingTimeout } = this.state
    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    this.setState({
      search: value,
      typing: true,
      typingTimeout: setTimeout(() => {
        this.search()
      }, 500)
    })
  }

  search () {
    this.setState({
      currentSearch: this.state.search,
      currentPage: 1,
      typing: false
    })
  }

  handleDelete (client, id) {
    return client.mutate({
      mutation: DELETE_BANNED_WORD,
      variables: {
        id: id
      }
    })
  }

  render () {
    let { search, currentSearch, currentPage, showBannedWordModal } = this.state
    return (
      <Card>
        <CardBody>
          <div className="pull-right">
            <Button onClick={() => this.setState({ showBannedWordModal: true })}>
              Add Banned Word
            </Button>
          </div>
          <h1>Banned Words</h1>

          <div className="input-group md-form form-sm form-1 pl-0">
            <div className="input-group-prepend">
              <span className="input-group-text lighten-3" id="basic-text1">
                <MDBIcon className="text-white" icon="search" />
              </span>
            </div>
            <input className="form-control my-0 py-1" type="text" placeholder="Search" aria-label="Search" value={search} onChange={event => this.typeSearch(event.target.value)} />
          </div>

          <Query
            query={GET_WORDS}
            variables={{ first: PAGE_SIZE, search: currentSearch }}
            fetchPolicy="network-only"
          >
            {({ client, loading, error, data, fetchMore, refetch }) => {
              if (loading) return 'Loading...'
              if (error) return `Error! ${error.message}`

              return (
                <>
                  {showBannedWordModal && (
                    <BannedWordModal
                      onClose={() => {
                        this.setState({ showBannedWordModal: false, editBannedWordItem: null })
                        refetch()
                      }}
                    />
                  )}
                  <Pagination
                    pageSize={PAGE_SIZE}
                    totalCount={data.bannedWords.totalCount || 0}
                    currentPage={currentPage}
                    fetchMore={fetchMore}
                    setCurrentPage={page =>
                      this.setState({ currentPage: page })
                    }
                  >
                    <BannedWordList
                      entries={data.bannedWords || []}
                      handleDelete={id =>
                        this.handleDelete(client, id).then(refetch)
                      }
                    />
                  </Pagination>
                </>
              )
            }}
          </Query>
        </CardBody>
      </Card>
    )
  }
}

export default BannedWordListPage
