import React, { Component } from 'react'

import { Card, CardBody } from 'mdbreact'

import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import POPOrderList from '../components/POPOrderList'
import Pagination from '../components/Pagination'

const PAGE_SIZE = 10

const GET_ORDERS = gql`
  query POPOrders($first: Int, $after: String) {
    popOrders(first: $first, after: $after) {
      totalCount
      edges {
        node {
          id
          order {
            id
            orderCode
          }
          pflId
          pflDetails
          pflStatusId
          pflStatusDescription
          createdAt
          updatedAt
        }
      }
    }
  }
`

export class POPOrderListPage extends Component {
  static propTypes = {}

  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      typing: false,
      typingTimeout: 0,
      search: '',
      currentSearch: ''
    }
  }

  typeSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout)
    }

    this.setState({
      search: event.target.value,
      typing: true,
      typingTimeout: setTimeout(() => {
        this.search()
      }, 500)
    })
  }

  search = () => {
    this.setState({
      currentSearch: this.state.search,
      currentPage: 1,
      typing: false
    })
  }

  render () {
    return (
      <Card>
        <CardBody>
          <h1>POP Orders</h1>

          {/*
          <div className="input-group md-form form-sm form-1 pl-0">
            <div className="input-group-prepend">
              <span className="input-group-text lighten-3" id="basic-text1">
                <MDBIcon className="text-white" icon="search" />
              </span>
            </div>
            <input className="form-control my-0 py-1" type="text" placeholder="Search" aria-label="Search" value={this.state.search} onChange={this.typeSearch} />
          </div>
          */}

          <Query
            query={GET_ORDERS}
            variables={{ first: PAGE_SIZE, search: this.state.currentSearch }}
            fetchPolicy="network-only"
          >
            {({ loading, error, data, fetchMore }) => {
              if (loading) return 'Loading...'
              if (error) return `Error! ${error.message}`

              return (
                <Pagination
                  pageSize={PAGE_SIZE}
                  totalCount={data.popOrders.totalCount || 0}
                  currentPage={this.state.currentPage}
                  fetchMore={fetchMore}
                  setCurrentPage={page => this.setState({ currentPage: page })}
                >
                  <POPOrderList entries={data.popOrders || []} />
                </Pagination>
              )
            }}
          </Query>
        </CardBody>
      </Card>
    )
  }
}

export default POPOrderListPage
