import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Moment from 'react-moment'

import {
  Table,
  TableBody,
  TableHead,
  MDBTooltip,
  MDBBadge,
  Button
} from 'mdbreact'

import POPOrderStatusModal from './modals/POPOrderStatusModal'
import { formatDateTime } from '../utils/dateUtils'

export default class POPOrderList extends Component {
  static propTypes = {
    entries: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      showModal: false,
      modalStatusDetails: '{}'
    }
  }

  handleShowDetails (details) {
    this.setState({
      modalStatusDetails: details,
      showModal: true
    })
  }

  render () {
    let { showModal } = this.state
    let { totalCount, edges } = this.props.entries
    return (
      <div>
        {totalCount && <span>{totalCount} results</span>}
        <Table className="btn-table">
          <TableHead textWhite color="unique-color-dark">
            <tr>
              <th>Order</th>
              <th>PFL ID</th>
              <th>PFL Status</th>
              <th>Details</th>
              <th style={{ textAlign: 'right' }}>Created</th>
              <th style={{ textAlign: 'right' }}>Updated</th>
            </tr>
          </TableHead>

          <TableBody color="white">
            {edges.map(({ node }) => (
              <tr key={node.id}>
                <td className="small text-muted text-monospace">
                  <Link to={`/orders/${node.order.id}`}>
                    {node.order.orderCode}
                  </Link>
                </td>
                <td>{node.pflId}</td>
                <td className="small">
                  <MDBTooltip
                    placement="bottom"
                    tag="span"
                    tooltipContent={`Status ID ${node.pflStatusId}`}
                  >
                    <MDBBadge
                      pill
                      color={node.pflStatusId === '2300' ? 'success' : 'light'}
                    >
                      {node.pflStatusDescription}
                    </MDBBadge>
                  </MDBTooltip>
                </td>
                <td>
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => this.handleShowDetails(node.pflDetails)}
                  >
                    Details
                  </Button>
                </td>
                <td style={{ textAlign: 'right' }}>
                  <MDBTooltip
                    placement="bottom"
                    tag="div"
                    tooltipContent={formatDateTime(node.createdAt)}
                  >
                    <Moment fromNow>{node.createdAt}</Moment>
                  </MDBTooltip>
                </td>
                <td style={{ textAlign: 'right' }}>
                  <MDBTooltip
                    placement="bottom"
                    tag="div"
                    tooltipContent={formatDateTime(node.updatedAt)}
                  >
                    <Moment fromNow>{node.updatedAt}</Moment>
                  </MDBTooltip>
                </td>
              </tr>
            ))}
          </TableBody>
        </Table>
        {showModal && (
          <POPOrderStatusModal
            onClose={() => this.setState({ showModal: false })}
            status={this.state.modalStatusDetails}
          />
        )}
      </div>
    )
  }
}
