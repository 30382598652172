import React from 'react'
import PropTypes from 'prop-types'

import { MDBTooltip, MDBIcon } from 'mdbreact'

const CheckMark = ({ isChecked, toolTip, hideFalse }) => {
  let icon = (
    <>
    { hideFalse && !isChecked ? '' : <MDBIcon
      icon={isChecked ? 'check' : 'times'}
      fixed
      className={isChecked ? 'text-success' : (hideFalse ? '' : 'text-danger')}
    />}
    </>

  )

  return toolTip ? (
    <MDBTooltip placement="bottom" tag="div" tooltipContent={toolTip}>
      {icon}
    </MDBTooltip>
  ) : (
    icon
  )
}

CheckMark.propTypes = {
  isChecked: PropTypes.bool,
  toolTip: PropTypes.string,
  hideFalse: PropTypes.bool
}

CheckMark.defaultProps = {
  hideFalse: false
}

export default CheckMark
