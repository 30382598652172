import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Table, TableBody, TableHead, MDBTooltip, MDBBadge, MDBIcon } from 'mdbreact'

import Currency from './elements/Currency'
import CreatedAt from './elements/CreatedAt'
import CheckMark from './elements/CheckMark'

export default class OrderList extends Component {
  static propTypes = {
    entries: PropTypes.object.isRequired
  }

  render () {
    let { totalCount, edges } = this.props.entries
    return (
      <div>
        {totalCount && <span>{totalCount} results</span>}
        <Table>
          <TableHead textWhite color="unique-color-dark">
            <tr>
              <th>#</th>
              <th>Store</th>
              <th>Status</th>
              <th>Disbursed</th>
              <th>Merchant ID</th>
              <th>First Sale</th>
              <th>Shipping</th>

              <th className="text-right">Amount</th>
              <th className="text-right">Tax</th>
              <th className="text-right">Shipping</th>
              <th className="text-right">PayPal Fee</th>
              <th className="text-right">HausMart Fee</th>
              <th className="text-right">Created</th>
            </tr>
          </TableHead>

          <TableBody color="white">
            {edges.map(order => (
              <tr key={order.node.id}>
                <td className="small text-muted text-monospace">
                  <Link to={`/orders/${order.node.id}`}>
                    {order.node.orderCode}
                  </Link>
                </td>

                <td className="small text-muted text-monospace">
                  <MDBTooltip
                    placement="bottom"
                    tag="div"
                    tooltipContent={ `${order.node.store.name}${order.node.store.isTestStore ? ' (Test Store)' : ''}`}
                  >
                    {order.node.store.shortId}{ ' ' }
                    { order.node.store.isTestStore ? <MDBIcon className="text-danger" fixed icon="times" /> : ''}
                  </MDBTooltip>
                </td>

                <td className="small">
                  <MDBBadge
                    pill
                    color={
                      order.node.orderStatus === 'COMPLETED'
                        ? 'success'
                        : order.node.orderStatus === 'ERROR'
                          ? 'warning'
                          : 'light'
                    }
                  >
                    {order.node.orderStatus}
                  </MDBBadge>
                </td>

                <td className="small"><CheckMark isChecked={order.node.orderDisbursed} /></td>
                <td className="small text-monospace text-muted">{ order.node.payeeMerchantId }</td>
                <td className="small"><CheckMark isChecked={order.node.vendorFirstOrder} hideFalse={true} /></td>
                <td className="small"><CheckMark isChecked={order.node.isShipping} hideFalse={true} /></td>
                <td className="text-right"><Currency currency={order.node.currency} amount={order.node.totalAmount} /></td>
                <td className="text-right"><Currency currency={order.node.currency} amount={order.node.tax} /></td>
                <td className="text-right"><Currency currency={order.node.shippingCurrency} amount={order.node.shippingAmount} /></td>
                <td className="text-right"><Currency currency={order.node.paypalTransactionFeeCurrency} amount={order.node.paypalTransactionFeeValue} /></td>
                <td className="text-right"><Currency currency={order.node.paypalPartnerFeeCurrency} amount={order.node.paypalPartnerFeeValue} /></td>
                <td className="text-right"><CreatedAt createdAt={order.node.createdAt} /></td>
              </tr>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }
}
