import React from 'react'
import PropTypes from 'prop-types'

import { Table, TableBody, TableHead, MDBIcon } from 'mdbreact'

import { formatDateTime } from '../utils/dateUtils'

const BannedWordList = ({ entries: { totalCount, edges }, handleDelete }) => (
  <div>
    <span>{totalCount} results</span>
    <Table>
      <TableHead textWhite color="unique-color-dark">
        <tr>
          <th>Word</th>
          <th>Type</th>
          <th>Description</th>
          <th>Created</th>
          <th></th>
        </tr>
      </TableHead>

      <TableBody color="white">
        {edges.map(({ node }) => (
          <tr key={node.id}>
            <td className="small">{node.word}</td>
            <td>{node.type}</td>
            <td>{node.description}</td>
            <td>{formatDateTime(node.createdAt)}</td>
            <td style={{ textAlign: 'center' }}>
              <MDBIcon
                icon="trash"
                fixed
                onClick={() => handleDelete(node.id)}
                style={{ cursor: 'pointer' }}
              />
            </td>
          </tr>
        ))}
      </TableBody>
    </Table>
  </div>
)

BannedWordList.propTypes = {
  entries: PropTypes.shape({
    totalCount: PropTypes.number.isRequired,
    edges: PropTypes.array.isRequired
  }),
  handleDelete: PropTypes.func.isRequired
}

export default BannedWordList
