import React, { Component } from 'react'

import { Card, CardBody } from 'mdbreact'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import BarcodeImageIssueList from '../components/BarcodeImageIssueList'
import BarcodeImageList from '../components/BarcodeImageList'
import Pagination from '../components/Pagination'

import BarcodeImageModal from '../components/modals/BarcodeImageModal'

const PAGE_SIZE = 10

const GET_ISSUES = gql`
  query ScannedBarcodeLogs($first: Int, $after: String) {
    scannedBarcodeLogs(first: $first, after: $after) {
      totalCount
      edges {
        node {
          id
          barcode
          reason
          productName
          hits
          lastSeen
          productsExist
        }
      }
    }
  }
`
const DELETE_ISSUE = gql`
  mutation DeleteScannedBarcodeLog($id: ID!) {
    deleteScannedBarcodeLog(input: { id: $id }) {
      success
    }
  }
`

const GET_CATALOG = gql`
  query ScannedBarcodes($first: Int, $after: String) {
    scannedBarcodes(first: $first, after: $after) {
      totalCount
      edges {
        node {
          id
          productName
          productBrand
          gtinType
          gtinCode
          isVerified
          createdAt
          updatedAt
        }
      }
    }
  }
`

export default class BarcodeImagesPage extends Component {
  static propTypes = {}

  constructor (props) {
    super(props)
    this.state = {
      selectedTab: 'issues',
      currentPage: 1,
      showModal: false,
      modalIssue: null
    }
  }

  handleTabClick (tab) {
    this.setState({ selectedTab: tab, currentPage: 1 })
  }

  handleDeleteIssue (client, id) {
    return client.mutate({
      mutation: DELETE_ISSUE,
      variables: {
        id: id
      }
    })
  }

  handleShowModal (issue) {
    this.setState({
      showModal: true,
      modalIssue: issue
    })
  }

  handleCloseModal (refetch, doRefresh) {
    this.setState({
      showModal: false,
      modalIssue: null
    }, () => doRefresh && refetch())
    
  }

  render () {
    let { selectedTab, currentPage, showModal, modalIssue } = this.state
    return (
      <Card>
        <CardBody>
          <h1>Barcode Images</h1>

          <Tabs
            activeKey={selectedTab}
            onSelect={tab => this.handleTabClick(tab)}
          >
            <Tab eventKey="issues" title="Issues" />
            <Tab eventKey="catalog" title="Catalog" />
          </Tabs>
          <div style={{ paddingTop: '10px ' }}>
            {selectedTab === 'issues' && (
              <Query
                query={GET_ISSUES}
                variables={{
                  first: PAGE_SIZE
                }}
                fetchPolicy="network-only"
              >
                {({ client, loading, error, data, fetchMore, refetch }) => {
                  if (loading) return 'Loading...'
                  if (error) return `Error! ${error.message}`

                  return (
                    <>
                      <Pagination
                        pageSize={PAGE_SIZE}
                        totalCount={data.scannedBarcodeLogs.totalCount || 0}
                        currentPage={currentPage}
                        fetchMore={fetchMore}
                        setCurrentPage={page =>
                          this.setState({ currentPage: page })
                        }
                      >
                        <BarcodeImageIssueList
                          entries={data.scannedBarcodeLogs || []}
                          handleSelect={issue => this.handleShowModal(issue)}
                          handleDelete={id =>
                            this.handleDeleteIssue(client, id).then(refetch)
                          }
                        />
                      </Pagination>
                      {showModal && (
                        <BarcodeImageModal
                          client={client}
                          values={modalIssue}
                          onClose={(doRefresh) => this.handleCloseModal(refetch, doRefresh)}
                        />
                      )}
                    </>
                  )
                }}
              </Query>
            )}
            {selectedTab === 'catalog' && (
              <Query
                query={GET_CATALOG}
                variables={{
                  first: PAGE_SIZE
                }}
                fetchPolicy="network-only"
              >
                {({ loading, error, data, fetchMore }) => {
                  if (loading) return 'Loading...'
                  if (error) return `Error! ${error.message}`

                  return (
                    <Pagination
                      pageSize={PAGE_SIZE}
                      totalCount={data.scannedBarcodes.totalCount || 0}
                      currentPage={currentPage}
                      fetchMore={fetchMore}
                      setCurrentPage={page =>
                        this.setState({ currentPage: page })
                      }
                    >
                      <BarcodeImageList entries={data.scannedBarcodes || []} />
                    </Pagination>
                  )
                }}
              </Query>
            )}
          </div>
        </CardBody>
      </Card>
    )
  }
}
