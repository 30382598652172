import React, { Component } from 'react'
import logo from '../images/hausmart.svg'
import { ListGroup, ListGroupItem, Fa } from 'mdbreact'
import { NavLink } from 'react-router-dom'

class SideNavigation extends Component {
  render () {
    return (
      <div className="sidebar-fixed position-fixed">
        <a href="#!" className="logo-wrapper waves-effect">
          <img alt="MDB React Logo" className="img-fluid" src={logo} />
        </a>
        <ListGroup className="list-group-flush">
          <NavLink exact={true} to="/" activeClassName="activeClass">
            <ListGroupItem>
              <Fa icon="pie-chart" className="mr-3" />
              Dashboard
            </ListGroupItem>
          </NavLink>
          <NavLink to="/users" activeClassName="activeClass">
            <ListGroupItem>
              <Fa icon="user" className="mr-3" /> Users
            </ListGroupItem>
          </NavLink>
          <NavLink to="/stores" activeClassName="activeClass">
            <ListGroupItem>
              <Fa icon="home" className="mr-3" /> Stores
            </ListGroupItem>
          </NavLink>
          <NavLink to="/orders" activeClassName="activeClass">
            <ListGroupItem>
              <Fa icon="file-text-o" className="mr-3" /> Orders
            </ListGroupItem>
          </NavLink>
          <NavLink to="/campaigns" activeClassName="activeClass">
            <ListGroupItem>
              <Fa icon="bullhorn" className="mr-3" /> Campaigns
            </ListGroupItem>
          </NavLink>
          <NavLink to="/claims" activeClassName="activeClass">
            <ListGroupItem>
              <Fa icon="hand-pointer-o" className="mr-3" /> Claims
            </ListGroupItem>
          </NavLink>
          <NavLink to="/pop" activeClassName="activeClass">
            <ListGroupItem>
              <Fa icon="print" className="mr-3" /> POP Orders
            </ListGroupItem>
          </NavLink>
          <NavLink to="/barcodeimages" activeClassName="activeClass">
            <ListGroupItem>
              <Fa icon="barcode" className="mr-3" /> Barcode Images
            </ListGroupItem>
          </NavLink>
          <NavLink to="/bannedwords" activeClassName="activeClass">
            <ListGroupItem>
              <Fa icon="ban" className="mr-3" /> Banned Words
            </ListGroupItem>
          </NavLink>
          <NavLink to="/reports/funnel">
            <ListGroupItem>
              <Fa icon="line-chart" className="mr-3" />
              Funnelytics
            </ListGroupItem>
          </NavLink>
        </ListGroup>
      </div>
    )
  }
}

export default SideNavigation
