import React, { Component } from 'react'

import { Card, CardBody, Button } from 'mdbreact'

import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import CampaignList from '../components/CampaignList'
import Pagination from '../components/Pagination'
import CampaignModal from '../components/modals/CampaignModal'

const PAGE_SIZE = 10

const GET_CAMPAIGNS = gql`
  query Campaigns($first: Int, $after: String) {
    campaigns(first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          code
          name
          createdAt
          isEnabled
          templateStore {
            id
            name
          }
          numGeneratedClaimables
        }
      }
      totalCount
    }
  }
`

export class CampaignListPage extends Component {
  static propTypes = {}

  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      typing: false,
      typingTimeout: 0,
      search: '',
      currentSearch: '',
      showCampaignModal: false,
      editCampaignItem: null
    }
  }

  typeSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout)
    }

    this.setState({
      search: event.target.value,
      typing: true,
      typingTimeout: setTimeout(() => {
        this.search()
      }, 500)
    })
  }

  search = () => {
    this.setState({
      currentSearch: this.state.search,
      currentPage: 1,
      typing: false
    })
  }

  showEditModal (item) {
    this.setState({
      showCampaignModal: true,
      editCampaignItem: item
    })
  }

  render () {
    return (
      <Card>
        <CardBody>
          <div className="pull-right">
            <Button onClick={() => this.setState({ showCampaignModal: true })}>
              Create a campaign
            </Button>
          </div>
          <h1>Campaigns</h1>

          <div className="input-group md-form form-sm form-1 pl-0">
            {/* <div className="input-group-prepend">
              <span className="input-group-text lighten-3" id="basic-text1">
                <MDBIcon className="text-white" icon="search" />
              </span>
            </div>
            <input className="form-control my-0 py-1" type="text" placeholder="Search" aria-label="Search" value={this.state.search} onChange={this.typeSearch} /> */}
          </div>

          <Query
            query={GET_CAMPAIGNS}
            variables={{ first: PAGE_SIZE, search: this.state.currentSearch }}
            fetchPolicy="network-only"
          >
            {({ loading, error, data, fetchMore, refetch }) => {
              if (loading) return 'Loading...'
              if (error) return `Error! ${error.message}`

              return (
                <>
                  {this.state.showCampaignModal && (
                    <CampaignModal
                      editCampaignItem={this.state.editCampaignItem}
                      onClose={() => {
                        this.setState({ showCampaignModal: false, editCampaignItem: null })
                        refetch()
                      }}
                    />
                  )}
                  <Pagination
                    pageSize={PAGE_SIZE}
                    totalCount={data.campaigns.totalCount || 0}
                    currentPage={this.state.currentPage}
                    fetchMore={fetchMore}
                    setCurrentPage={page =>
                      this.setState({ currentPage: page })
                    }
                  >
                    <CampaignList
                      entries={data.campaigns || []}
                      onRowClicked={rowId => this.showEditModal(rowId)}
                    />
                  </Pagination>
                </>
              )
            }}
          </Query>
        </CardBody>
      </Card>
    )
  }
}

export default CampaignListPage
