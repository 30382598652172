import React, { Component } from 'react'
import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'
import { withFormik } from 'formik'
import gql from 'graphql-tag'
import * as yup from 'yup'
import classNames from 'classnames'

import { compose, withApollo, graphql } from 'react-apollo'
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from 'mdbreact'
import AsyncSelect from 'react-select/lib/Async'

import TemplateStoreSelect from '../elements/TemplateStoreSelect'
import { serverValidationToForm } from '../../utils/formUtils'

const SEARCH_CAMPAIGNS = gql`
  query CampaignSearch($search: String) {
    campaigns(first: 15, search: $search) {
      edges {
        cursor
        node {
          id
          name
          templateStore {
            id
            name
          }
        }
      }
    }
  }
`

const MUTATION = gql`
  mutation CreateTemplateStoreClaim(
    $templateStoreId: ID!
    $campaignId: ID
    $shortId: String
  ) {
    createTemplateStoreClaim(
      input: {
        templateStoreId: $templateStoreId
        campaignId: $campaignId
        shortId: $shortId
      }
    ) {
      templateStoreClaim {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

const BATCH_MUTATION = gql`
  mutation CreateBatchTemplateStoreClaim(
    $templateStoreId: ID!
    $campaignId: ID
    $count: Int!
    $batchName: String
  ) {
    createBatchTemplateStoreClaim(
      input: {
        templateStoreId: $templateStoreId
        campaignId: $campaignId
        count: $count
        batchName: $batchName
      }
    ) {
      count
      batchName
      errors {
        field
        messages
      }
    }
  }
`

const enhancer = withFormik({
  validationSchema: yup.object().shape({
    batch: yup.bool().required(),
    shortId: yup
      .string()
      .min(5)
      .max(5)
      .label('Store ID'),
    templateStoreId: yup
      .object()
      .required()
      .label('Template store'),
    campaignId: yup.object().label('Template store'),
    batchName: yup.string().label('Batch name'),
    count: yup
      .number()
      .when('batch', {
        is: true,
        then: yup.number().min(1)
      })
      .label('Count')
  }),

  mapPropsToValues: props => {
    return {
      batch: props.batch || false,
      shortId: '',
      templateStoreId: '',
      campaignId: '',
      batchName: '',
      count: 0
    }
  },

  handleSubmit: async (values, { props, setSubmitting, setFieldError }) => {
    let mutation
    if (values.batch) {
      mutation = await props.createBatchTemplateStoreClaim({
        variables: {
          templateStoreId: values.templateStoreId.value,
          campaignId: values.campaignId ? values.campaignId.value : '',
          count: values.count,
          batchName: values.batchName
        }
      })
    } else {
      mutation = await props.createTemplateStoreClaim({
        variables: {
          templateStoreId: values.templateStoreId.value,
          campaignId: values.campaignId ? values.campaignId.value : '',
          shortId: values.shortId
        }
      })
    }

    setSubmitting(false)

    if (values.batch && mutation.data.createBatchTemplateStoreClaim.errors) {
      serverValidationToForm({
        errors: mutation.data.createBatchTemplateStoreClaim.errors,
        setFieldError,
        errorFieldMap: { response: 'count' }
      })
    } else if (!values.batch && mutation.data.createTemplateStoreClaim.errors) {
      serverValidationToForm({
        errors: mutation.data.createTemplateStoreClaim.errors,
        setFieldError,
        errorFieldMap: { response: 'shortId' }
      })
    } else {
      if (props.onCreated) {
        props.onCreated()
      }
      /* props.client.clearStore()
      auth.login(tokenAuth.data.tokenAuth.token, tokenAuth.data.tokenAuth.refresh)
      props.history.push('/') */
    }
  }
})

class ClaimCreateModal extends Component {
  static propTypes = {
    batch: PropTypes.bool,
    onCreated: PropTypes.func,
    onClose: PropTypes.func,
    show: PropTypes.bool.isRequired,
    client: PropTypes.object.isRequired,
    touched: PropTypes.object,
    errors: PropTypes.object,
    values: PropTypes.object,
    status: PropTypes.string,
    isSubmitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setStatus: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired
  }

  toggle = () => {
    this.props.onClose()
  }

  idNameOptions (query, data, client, inputValue, callback) {
    client
      .query({
        query: query,
        variables: {
          search: inputValue
        }
      })
      .then(result => {
        callback(
          result.data[data].edges.map(edge => ({
            value: edge.node.id,
            label: edge.node.name,
            obj: edge.node
          }))
        )
      })
  }

  render () {
    return (
      <MDBModal isOpen={this.props.show} toggle={this.toggle} size="lg">
        <form onSubmit={this.props.handleSubmit} noValidate>
          <MDBModalHeader toggle={this.toggle}>
            Create a claimable code
          </MDBModalHeader>
          <MDBModalBody>
            {this.props.batch ? (
              ''
            ) : (
              <div className="form-group">
                <label htmlFor="shortId">Store ID</label>
                <input
                  id="shortId"
                  name="shortId"
                  type="text"
                  value={this.props.values.shortId}
                  onBlur={this.props.handleBlur}
                  onChange={this.props.handleChange}
                  className={classNames({
                    'form-control': true,
                    'is-invalid':
                      this.props.status === 'submitted' &&
                      this.props.errors.shortId
                  })}
                />
                {this.props.status === 'submitted' &&
                  this.props.errors.shortId && (
                  <div className="invalid-feedback d-block">
                    {this.props.errors.shortId}
                  </div>
                )}
              </div>
            )}

            {this.props.batch ? (
              <React.Fragment>
                <div className="form-group">
                  <label htmlFor="count">Count</label>
                  <input
                    id="count"
                    name="count"
                    type="number"
                    value={this.props.values.count}
                    onBlur={this.props.handleBlur}
                    onChange={this.props.handleChange}
                    className={classNames({
                      'form-control': true,
                      'is-invalid':
                        this.props.status === 'submitted' &&
                        this.props.errors.count
                    })}
                  />
                  {this.props.status === 'submitted' &&
                    this.props.errors.count && (
                    <div className="invalid-feedback d-block">
                      {this.props.errors.count}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="batchName">Batch name</label>
                  <input
                    id="batchName"
                    name="batchName"
                    type="text"
                    value={this.props.values.batchName}
                    onBlur={this.props.handleBlur}
                    onChange={this.props.handleChange}
                    className={classNames({
                      'form-control': true,
                      'is-invalid':
                        this.props.status === 'submitted' &&
                        this.props.errors.batchName
                    })}
                  />
                  {this.props.status === 'submitted' &&
                    this.props.errors.batchName && (
                    <div className="invalid-feedback d-block">
                      {this.props.errors.batchName}
                    </div>
                  )}
                </div>
              </React.Fragment>
            ) : (
              ''
            )}

            <div className="form-group">
              <label htmlFor="campaignId">Campaign</label>
              <AsyncSelect
                id="campaignId"
                name="campaignId"
                defaultOptions
                cacheOptions
                loadOptions={debounce(
                  (inputValue, callback) =>
                    this.idNameOptions(
                      SEARCH_CAMPAIGNS,
                      'campaigns',
                      this.props.client,
                      inputValue,
                      callback
                    ),
                  500
                )}
                value={this.props.values.campaignId}
                onBlur={this.props.handleBlur}
                onChange={value => {
                  this.props.setFieldValue('campaignId', value)
                  this.props.setFieldValue('templateStoreId', {
                    value: value.obj.templateStore.id,
                    label: value.obj.templateStore.name,
                    obj: value.obj.templateStore
                  })
                }}
                className={classNames({
                  'is-invalid':
                    this.props.status === 'submitted' &&
                    this.props.errors.shortId
                })}
                isClearable
              />
              {this.props.status === 'submitted' &&
                this.props.errors.campaignId && (
                <div className="invalid-feedback d-block">
                  {this.props.errors.campaignId}
                </div>
              )}
            </div>

            <div className="form-group">
              <TemplateStoreSelect
                client={this.props.client}
                value={this.props.values.templateStoreId}
                errors={this.props.errors}
                status={this.props.status}
                onChange={value =>
                  this.props.setFieldValue('templateStoreId', value)
                }
                handleBlur={this.props.handleBlur}
              />
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="primary"
              disabled={this.props.isSubmitting}
              type="submit"
              onClick={() => this.props.setStatus('submitted')}
            >
              Save
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </MDBModal>
    )
  }
}

export default compose(
  withApollo,
  graphql(MUTATION, { name: 'createTemplateStoreClaim' }),
  graphql(BATCH_MUTATION, { name: 'createBatchTemplateStoreClaim' }),
  enhancer
)(ClaimCreateModal)
