import React from 'react'
import PropTypes from 'prop-types'

import ReactPaginate from 'react-paginate'
import { b64EncodeUnicode } from '../utils/base64'

export const parseAfter = (page, pageSize) => {
  return page > 1 ? b64EncodeUnicode(`arrayconnection:${((page - 1) * pageSize) - 1}`) : null
}

export const Pagination = ({ pageSize, totalCount, currentPage, fetchMore, setCurrentPage, children }) => {
  const pageCount = Math.ceil(
    totalCount / pageSize
  )

  const onLoadPage = (page) => {
    const after = parseAfter(page, pageSize)

    fetchMore({
      variables: {
        after: after,
        first: pageSize
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        setCurrentPage(page)
        return fetchMoreResult
      }
    })
  }

  const pagination = <ReactPaginate
    pageCount={pageCount}
    marginPagesDisplayed={2}
    pageRangeDisplayed={5}
    forcePage={currentPage - 1}
    containerClassName="pagination"
    pageClassName="page-item"
    pageLinkClassName="page-link"
    activeClassName={'active'}
    previousClassName="page-item"
    previousLinkClassName="page-link"
    nextClassName="page-item"
    nextLinkClassName="page-link"
    disabledClassName="disabled"
    disableInitialCallback={true}
    onPageChange={(data) => {
      onLoadPage(data.selected + 1)
    }}
  />

  return (<>
    <div className="pull-right">{pagination}</div>
      {children}
    <div className="pull-right">{pagination}</div>
  </>)
}
Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  fetchMore: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired
}

export default Pagination
