import React, { Component } from 'react'
// import PropTypes from 'prop-types'

import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import classNames from 'classnames'

import { Table, TableBody, TableHead, MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol, MDBCardText, MDBBtnGroup, MDBBtn, MDBIcon } from 'mdbreact'
import Spinner from 'react-spinkit'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import { LineChart, Line, Tooltip, XAxis, ResponsiveContainer } from 'recharts'

const STATS_QUERY = gql`
query StatsReportQuery($duration: ReportDuration!) {
  reports {
    durationTrendKeys(duration:$duration)
    userCount(duration:$duration, withTrend: true) { value, delta, trend { key, value } }
    enabledStores:storeCount(duration:$duration,isDeleted:false,isEnabled:true, withTrend: true) { value, delta, trend { key, value } }
    disabledStores:storeCount(duration:$duration,isDeleted:false,isEnabled:false) { value, delta }
    deletedStores:storeCount(duration:$duration,isDeleted:true) { value, delta }
    privateStores:storeCount(duration:$duration,isDeleted:false, isEnabled:true, isPrivate:true) { value, delta }
    geoStores:storeCount(duration:$duration,isDeleted:false, isEnabled:true, allLocationsAllowed:false) { value, delta }
    stockStores:storeCount(duration:$duration,isDeleted:false, isEnabled:true, isStockEnabled:true) { value, delta }
    shippingStores:storeCount(duration:$duration,isDeleted:false, isEnabled:true, isShippingEnabled:true) { value, delta }
    tagsStores:storeCount(duration:$duration,isDeleted:false, isEnabled:true, isTagsEnabled:true) { value, delta }
    taxStores:storeCount(duration:$duration,isDeleted:false, isEnabled:true, isTaxEnabled:true) { value, delta }
    productStores:storeCount(duration:$duration,isDeleted:false, isEnabled:true, hasProducts:true) { value, delta }
    averageProductCount(duration:$duration) { value, delta }
    orderCount(duration:$duration, withTrend: true) { value, delta, trend { key, value } }
    orderCancelledCount: orderCount(duration:$duration,status:[CANCELLED], withTrend: true) { value, delta, trend { key, value } }
    orderErrorCount: orderCount(duration:$duration,status:[ERROR], withTrend: true) { value, delta, trend { key, value } }
    orderSum(duration:$duration, withTrend: true) { value, delta, trend { key, value } }
  }
}
`

const STORE_PART_FRAGMENT = gql`
  fragment StoreParts on StoreType {
    id
    shortId
    name
    webUrl(notify:false)
  }
`

const TOTAL_AMOUNT_QUERY = gql`
  ${STORE_PART_FRAGMENT}
  query TotalAmountReportsQuery($duration: ReportDuration!) {
    reports {
      topRevenueStores: topStores(top:5,orderBy:"-orderSum,-orderCount") {
          ...StoreParts
          orderSum(duration: $duration)
          orderCount(duration: $duration)
      }
    }
  }
`

const ORDER_COUNT_QUERY = gql`
${STORE_PART_FRAGMENT}
  query OrderCountReportsQuery($duration: ReportDuration!) {
    reports {
      topOrderCountStores: topStores(top:5,orderBy:"-orderCount,-orderSum") {
          ...StoreParts
          orderSum(duration: $duration)
          orderCount(duration: $duration)
      }
    }
  }
`

const UNIT_SALES_QUERY = gql`
${STORE_PART_FRAGMENT}
  query TopQuantityReportsQuery($duration: ReportDuration!) {
    reports {
      topQuantityStores: topStores(top:5,orderBy:"-quantitySold,-orderSum") {
        ...StoreParts
        quantitySold(duration: $duration)
        orderSum(duration: $duration)
      }
    }
  }
`

const StatsCardDelta = props => {
  return (
    <span className={classNames({ 'text-success': props.delta >= 0, 'text-danger': props.delta < 0 })}>
      { props.delta >= 0 ? <MDBIcon icon="level-up" /> : <MDBIcon icon="level-down" /> }{ ' ' }
      { Math.round(Math.abs(props.delta * 10)) / 10 }
    </span>
  )
}

const StatsCard = props => {
  return (
    <MDBCard style={{ boxShadow: 'none' }} className="mt-2">
      <MDBCardBody>
        <MDBCardTitle tag="h5" className="text-center">
          { props.title }
        </MDBCardTitle>
        <MDBCardText className="text-center">
          <span className="h1 m-0 d-block">{ Array.isArray(props.stat) ? props.stat.map((stat, idx) => (
            <React.Fragment key={ idx }>{ idx === 0 ? '' : ' / '} { stat.value }</React.Fragment>
          )) : props.stat.value}</span>
          <small>{ Array.isArray(props.subtitle) ? props.subtitle.map((subtitle, idx) => (
            <React.Fragment key={ idx }>{ idx === 0 ? '' : ' / '} { subtitle }</React.Fragment>
          )) : props.subtitle}</small>
          <br />
          <span className="small">{ Array.isArray(props.stat) ? props.stat.map((stat, idx) => (
            <span key={ idx }>{ idx === 0 ? '' : ' / '} <StatsCardDelta delta={ stat.delta } /></span>
          )) : (
            <StatsCardDelta delta={ props.stat.delta } />
          )}</span>
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>
  )
}

const Trend = props => {
  // const data = props.keys.map(k => ({
  //   'name': k,
  //   'pv': 0
  // }))

  const data = {}

  if (!props.keys || !props.stat) {
    return (<></>)
  }

  props.keys.forEach(k => { data[k] = Number(0) })

  props.stat.forEach(stat => {
    data[stat.key] += Number(stat.value)
  })

  const chartData = Object.keys(data).map((k, i) => ({
    name: k,
    pv: data[k]
  }))

  return (
    <div style={{ width: '100%', height: 50 }}>
      <ResponsiveContainer>
        <LineChart data={chartData}>
          <Tooltip wrapperStyle={{ zIndex: 9999 }} />
          <XAxis dataKey="name" hide={true} />
          <Line type="linear" dataKey="pv" stroke="#55bbbb" dot={false} name="Value" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default class HomeStoreRevenueReport extends Component {
  // static propTypes = {
  //   prop: PropTypes
  // }

  constructor (props) {
    super(props)
    this.state = {
      statsTab: 'REPORT_DURATION_7D',
      totalAmountTab: 'REPORT_DURATION_7D',
      orderCountTab: 'REPORT_DURATION_7D',
      unitSalesTab: 'REPORT_DURATION_7D'
    }
  }
  render () {
    return (
      <>
      <MDBCard className="mb-4">
        <MDBCardBody>
          <MDBRow>
            <MDBCol size="12">
              <div className="pull-right">
                <MDBBtnGroup size="sm">
                  <MDBBtn onClick={() => this.setState({ statsTab: 'REPORT_DURATION_24H' })} color={ this.state.statsTab === 'REPORT_DURATION_24H' ? 'pink' : 'blue-grey' }>24 Hours</MDBBtn>
                  <MDBBtn onClick={() => this.setState({ statsTab: 'REPORT_DURATION_7D' })} color={ this.state.statsTab === 'REPORT_DURATION_7D' ? 'pink' : 'blue-grey' }>7 Days</MDBBtn>
                  <MDBBtn onClick={() => this.setState({ statsTab: 'REPORT_DURATION_30D' })} color={ this.state.statsTab === 'REPORT_DURATION_30D' ? 'pink' : 'blue-grey' }>30 Days</MDBBtn>
                  <MDBBtn onClick={() => this.setState({ statsTab: 'REPORT_DURATION_ALL' })} color={ this.state.statsTab === 'REPORT_DURATION_ALL' ? 'pink' : 'blue-grey' }>All time</MDBBtn>
                </MDBBtnGroup>
              </div>
            </MDBCol>
          </MDBRow>

          <Query
            query={STATS_QUERY}
            variables={{ duration: this.state.statsTab }}
            fetchPolicy="network-only"
          >
            {({ loading, error, data, fetchMore, refetch }) => {
              if (loading) return <div className="text-center"><Spinner name="three-bounce" className="text-hausmart-pink"/></div>
              if (error) return `Error! ${error.message}`

              return (<>
          <MDBRow className="justify-content-center">
            <MDBCol size="2">
              <StatsCard title="New Users" stat={ data.reports.userCount } subtitle="Unique emails" />
              <Trend stat={ data.reports.userCount.trend } keys={ data.reports.durationTrendKeys } />
            </MDBCol>
            <MDBCol size="2">
              <StatsCard title="New Stores" stat={ data.reports.enabledStores } subtitle="Enabled stores" />
              <Trend stat={ data.reports.enabledStores.trend } keys={ data.reports.durationTrendKeys } />
            </MDBCol>
            <MDBCol size="2">
              <StatsCard title="Product Stores" stat={ data.reports.productStores } subtitle="Stores with products" />
            </MDBCol>
            <MDBCol size="2">
              <StatsCard title="Average Products" stat={ data.reports.averageProductCount } subtitle="Average product count per store" />
            </MDBCol>
            <MDBCol size="2">
              <StatsCard
                title="Disabled Stores"
                stat={ [data.reports.disabledStores, data.reports.deletedStores] }
                subtitle={ ['Disabled', 'Deleted stores'] } />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="2">
              <StatsCard title="Private Stores" stat={ data.reports.privateStores } subtitle="Private stores" />
            </MDBCol>
            <MDBCol size="2">
              <StatsCard title="Geolocked Stores" stat={ data.reports.geoStores } subtitle="Location based stores" />
            </MDBCol>
            <MDBCol size="2">
              <StatsCard title="Inventory Stores" stat={ data.reports.stockStores } subtitle="Stores with inventory" />
            </MDBCol>
            <MDBCol size="2">
              <StatsCard title="Shipping Stores" stat={ data.reports.shippingStores } subtitle="Stores with shipping" />
            </MDBCol>
            <MDBCol size="2">
              <StatsCard title="Category Stores" stat={ data.reports.tagsStores } subtitle="Stores with categories" />
            </MDBCol>
            <MDBCol size="2">
              <StatsCard title="TAX Stores" stat={ data.reports.taxStores } subtitle="Stores with tax" />
            </MDBCol>
          </MDBRow>
          <MDBRow className="justify-content-center">
            <MDBCol size="2">
              <StatsCard title="New Orders" stat={ data.reports.orderCount } subtitle="Orders completed" />
              <Trend stat={ data.reports.orderCount.trend } keys={ data.reports.durationTrendKeys } />
            </MDBCol>
            <MDBCol size="4">
              <StatsCard title="Order Sum" stat= {{
                'value': new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD'
                }).format(data.reports.orderSum.value),
                'delta': data.reports.orderSum.delta }} subtitle="Orders paid for" />
              <Trend stat={ data.reports.orderSum.trend } keys={ data.reports.durationTrendKeys } />
            </MDBCol>
            <MDBCol size="2">
              <StatsCard title="Cancelled Orders" stat={ data.reports.orderCancelledCount } subtitle="Orders cancelled" />
              <Trend stat={ data.reports.orderCancelledCount.trend } keys={ data.reports.durationTrendKeys } />
            </MDBCol>
            <MDBCol size="2">
              <StatsCard title="Order Errors" stat={ data.reports.orderErrorCount } subtitle="Orders with errors" />
              <Trend stat={ data.reports.orderErrorCount.trend } keys={ data.reports.durationTrendKeys } />
            </MDBCol>
          </MDBRow>
          </>)
            }}
          </Query>
        </MDBCardBody>
      </MDBCard>
      <MDBRow>
        <MDBCol size="4">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>Store Revenue</MDBCardTitle>
              <Tabs id="report-store-total-amount-tabs" activeKey={this.state.totalAmountTab} onSelect={totalAmountTab => this.setState({ totalAmountTab })}>
                <Tab eventKey="REPORT_DURATION_24H" title="24 Hours"></Tab>
                <Tab eventKey="REPORT_DURATION_7D" title="7 Days"></Tab>
                <Tab eventKey="REPORT_DURATION_30D" title="30 Days"></Tab>
                <Tab eventKey="REPORT_DURATION_ALL" title="All"></Tab>
              </Tabs>
              <Query
                query={TOTAL_AMOUNT_QUERY}
                variables={{ duration: this.state.totalAmountTab }}
                fetchPolicy="network-only"
              >
                {({ loading, error, data, fetchMore, refetch }) => {
                  if (loading) return 'Loading...'
                  if (error) return `Error! ${error.message}`

                  return (<Table>
                    <TableHead textWhite color="unique-color-dark">
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th style={{ textAlign: 'right' }}></th>
                      </tr>
                    </TableHead>
                    <TableBody color="white">
                      {data.reports.topRevenueStores.map((node) => (
                        <tr key={node.shortId}>
                          <td className="small text-muted text-monospace"><a href={ node.webUrl } target="_blank" rel="noopener noreferrer">{ node.shortId }</a></td>
                          <td>
                            { node.name.length > 30 ? `${node.name.substring(0, 27)}...` : node.name }
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD'
                            }).format(node.orderSum)}{ ' ' }
                            <span className="text-muted small">({ node.orderCount })</span>
                          </td>
                        </tr>
                      ))}
                    </TableBody>
                  </Table>)
                }}</Query>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol size="4">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>Store Sales</MDBCardTitle>
              <Tabs id="report-store-order-count-tabs" activeKey={this.state.orderCountTab} onSelect={orderCountTab => this.setState({ orderCountTab })}>
                <Tab eventKey="REPORT_DURATION_24H" title="24 Hours"></Tab>
                <Tab eventKey="REPORT_DURATION_7D" title="7 Days"></Tab>
                <Tab eventKey="REPORT_DURATION_30D" title="30 Days"></Tab>
                <Tab eventKey="REPORT_DURATION_ALL" title="All"></Tab>
              </Tabs>
              <Query
                query={ORDER_COUNT_QUERY}
                variables={{ duration: this.state.orderCountTab }}
                fetchPolicy="network-only"
              >
                {({ loading, error, data, fetchMore, refetch }) => {
                  if (loading) return 'Loading...'
                  if (error) return `Error! ${error.message}`

                  return (<Table>
                    <TableHead textWhite color="unique-color-dark">
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th style={{ textAlign: 'right' }}></th>
                      </tr>
                    </TableHead>
                    <TableBody color="white">
                      {data.reports.topOrderCountStores.map((node) => (
                        <tr key={node.shortId}>
                          <td className="small text-muted text-monospace"><a href={ node.webUrl } target="_blank" rel="noopener noreferrer">{ node.shortId }</a></td>
                          <td>
                            { node.name.length > 30 ? `${node.name.substring(0, 27)}...` : node.name }
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            { node.orderCount }{ ' ' }
                            <span className="text-muted small">({new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD'
                            }).format(node.orderSum)})</span>
                          </td>
                        </tr>
                      ))}
                    </TableBody>
                  </Table>)
                }}</Query>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol size="4">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>Unit Sales</MDBCardTitle>
              <Tabs id="report-store-total-amount-tabs" activeKey={this.state.unitSalesTab} onSelect={unitSalesTab => this.setState({ unitSalesTab })}>
                <Tab eventKey="REPORT_DURATION_24H" title="24 Hours"></Tab>
                <Tab eventKey="REPORT_DURATION_7D" title="7 Days"></Tab>
                <Tab eventKey="REPORT_DURATION_30D" title="30 Days"></Tab>
                <Tab eventKey="REPORT_DURATION_ALL" title="All"></Tab>
              </Tabs>
              <Query
                query={UNIT_SALES_QUERY}
                variables={{ duration: this.state.unitSalesTab }}
                fetchPolicy="network-only"
              >
                {({ loading, error, data, fetchMore, refetch }) => {
                  if (loading) return 'Loading...'
                  if (error) return `Error! ${error.message}`

                  return (<Table>
                    <TableHead textWhite color="unique-color-dark">
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th style={{ textAlign: 'right' }}></th>
                      </tr>
                    </TableHead>
                    <TableBody color="white">
                      {data.reports.topQuantityStores.map((node) => (
                        <tr key={node.shortId}>
                          <td className="small text-muted text-monospace"><a href={ node.webUrl } target="_blank" rel="noopener noreferrer">{ node.shortId }</a></td>
                          <td>
                            { node.name.length > 30 ? `${node.name.substring(0, 27)}...` : node.name }
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            { node.quantitySold }{ ' ' }
                            <span className="text-muted small">({new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD'
                            }).format(node.orderSum)})</span>
                          </td>
                        </tr>
                      ))}
                    </TableBody>
                  </Table>)
                }}</Query>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      </>
    )
  }
}
