import React from 'react'
import PropTypes from 'prop-types'

import Moment from 'react-moment'
import { formatDateTime } from '../utils/dateUtils'

import { Table, TableBody, TableHead, MDBTooltip, MDBIcon } from 'mdbreact'

const CampaignList = ({ entries, onRowClicked }) => (
  <div>
    <span>{entries.totalCount} results</span>
    <Table>
      <TableHead textWhite color="unique-color-dark">
        <tr>
          <th>#</th>
          <th>Name</th>
          <th className="text-center">Enabled</th>
          <th className="text-right">Created</th>
          <th></th>
        </tr>
      </TableHead>

      <TableBody color="white">
        {entries.edges.map(({ node }) => (
          <tr key={node.id}>
            <td className="small text-muted text-monospace">{node.code}</td>
            <td className="small">{node.name}</td>
            <td className="text-center">
              <MDBIcon
                icon={node.isEnabled ? 'check' : 'times'}
                fixed
                className={node.isEnabled ? 'text-success' : 'text-danger'}
              />
            </td>
            <td className="text-right">
              <MDBTooltip
                placement="bottom"
                tag="div"
                tooltipContent={formatDateTime(node.createdAt)}
              >
                <Moment fromNow>{node.createdAt}</Moment>
              </MDBTooltip>
            </td>
            <td className="text-center">
              <MDBIcon
                icon="pencil"
                fixed
                onClick={() => onRowClicked(node)}
                style={{ cursor: 'pointer' }}
              />
            </td>
          </tr>
        ))}
      </TableBody>
    </Table>
  </div>
)

CampaignList.propTypes = {
  entries: PropTypes.shape({
    totalCount: PropTypes.number.isRequired,
    edges: PropTypes.array.isRequired
  }),
  onRowClicked: PropTypes.func
}

export default CampaignList
