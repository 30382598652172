import gql from 'graphql-tag'
import PropTypes from 'prop-types'

const tableFilterPropType = PropTypes.shape({
  query: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  getOptions: PropTypes.func.isRequired
})

const FILTERS = {
  email: {
    query: gql`
      query EmailSearch($search: String) {
        templateStoreClaims(first: 10, email: $search) {
          edges {
            cursor
            node {
              id
              requestEmail
            }
          }
        }
      }
    `,
    name: 'templateStoreClaims',
    getOptions: edge => ({
      value: edge.node.id,
      label: edge.node.requestEmail,
      obj: edge.node
    })
  },
  campaign: {
    query: gql`
      query CampaignSearch($search: String) {
        campaigns(search: $search) {
          edges {
            node {
              id
              code
              name
            }
          }
        }
      }
    `,
    name: 'campaigns',
    getOptions: edge => ({
      value: edge.node.id,
      label: edge.node.code,
      obj: edge.node.campaign
    })
  },
  shortId: {
    query: gql`
      query shortIdSearch($search: String) {
        templateStoreClaims(first: 10, shortId: $search) {
          edges {
            cursor
            node {
              id
              shortId
            }
          }
        }
      }
    `,
    name: 'templateStoreClaims',
    getOptions: edge => ({
      value: edge.node.id,
      label: edge.node.shortId,
      obj: edge.node
    })
  },
  paypalEmail: {
    query: gql`
      query PaypalEmailSearch($search: String) {
        templateStoreClaims(first: 10, paypalEmail: $search) {
          edges {
            cursor
            node {
              id
              requestEmail
            }
          }
        }
      }
    `,
    name: 'templateStoreClaims',
    getOptions: edge => ({
      value: edge.node.id,
      label: edge.node.requestEmail,
      obj: edge.node
    })
  }
}

export { FILTERS, tableFilterPropType }
