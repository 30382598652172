import React, { Component } from 'react'

import { Card, CardBody, Button, MDBIcon } from 'mdbreact'

import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import OrderList from '../components/OrderList'
import Pagination from '../components/Pagination'
import DownloadModal from '../components/modals/DownloadModal'

const PAGE_SIZE = 10

export const ORDER_LIST_PARTS = gql`
fragment OrderListParts on OrderType {
  id
  orderCode
  orderStatus
  orderDisbursed
  payeeMerchantId
  vendorFirstOrder
  isShipping

  currency
  totalAmount
  tax

  shippingAmount
  shippingCurrency

  paypalTransactionFeeValue
  paypalTransactionFeeCurrency

  paypalPartnerFeeValue
  paypalPartnerFeeCurrency

  createdAt
  store {
    id
    name
    shortId
    isTestStore
  }
}
`

const GET_ORDERS = gql`
  ${ORDER_LIST_PARTS}
  query Orders($first: Int, $after: String) {
    orders(first: $first, after: $after) {
      edges {
        cursor
        node {
          ...OrderListParts
        }
      }
      totalCount
      reportUrl
    }
  }
`

export class OrderListPage extends Component {
  static propTypes = {}

  constructor (props) {
    super(props)
    this.state = { currentPage: 1, typing: false, typingTimeout: 0, search: '', currentSearch: '' }
  }

  typeSearch = (event) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout)
    }

    this.setState({
      search: event.target.value,
      typing: true,
      typingTimeout: setTimeout(() => {
        this.search()
      }, 500)
    })
  }

  search = () => {
    this.setState({
      currentSearch: this.state.search,
      currentPage: 1,
      typing: false,
      showDownloadModal: false,
      downloadURL: null
    })
  }

  render () {
    let { showDownloadModal, downloadURL } = this.state
    return (
      <Card>
        <CardBody>
          <h1>Orders</h1>

          { /*
          <div className="input-group md-form form-sm form-1 pl-0">
            <div className="input-group-prepend">
              <span className="input-group-text lighten-3" id="basic-text1">
                <MDBIcon className="text-white" icon="search" />
              </span>
            </div>
            <input className="form-control my-0 py-1" type="text" placeholder="Search" aria-label="Search" value={this.state.search} onChange={this.typeSearch} />
          </div>
          */ }

          <Query query={GET_ORDERS} variables={{ 'first': PAGE_SIZE, 'search': this.state.currentSearch }} fetchPolicy="network-only">
            {({ client, loading, error, data, fetchMore }) => {
              if (loading) return 'Loading...'
              if (error) return `Error! ${error.message}`

              return (
                <>
                  <div className="pull-right">
                    <Button
                      id="export-button"
                      color="primary"
                      size="sm"
                      onClick={() => this.setState({
                        showDownloadModal: true,
                        downloadURL: data.orders.reportUrl
                      })}
                    >
                      <MDBIcon icon="file-excel-o" fixed /> Order Report
                    </Button>
                  </div>
                  <Pagination
                    pageSize={PAGE_SIZE}
                    totalCount={data.orders.totalCount || 0}
                    currentPage={this.state.currentPage}
                    fetchMore={fetchMore}
                    setCurrentPage={page => this.setState({ currentPage: page })} >
                    <OrderList entries={data.orders || []} />
                  </Pagination>
                </>)
            }}
          </Query>
          {showDownloadModal && (
            <DownloadModal
              toggle={() => this.setState({ showDownloadModal: false, downloadURL: null })}
              downloadURL={downloadURL}
            />
          )}
        </CardBody>
      </Card>
    )
  }
}

export default OrderListPage
