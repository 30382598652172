import React from 'react'
import PropTypes from 'prop-types'

import Moment from 'react-moment'
import moment from 'moment'

import { MDBTooltip } from 'mdbreact'

const CreatedAt = ({ createdAt }) => {
  return (
    <MDBTooltip
      placement="bottom"
      tag="div"
      tooltipContent={moment(createdAt)
        .tz('Atlantic/Reykjavik')
        .format('lll z')}
    >
      <Moment fromNow>{createdAt}</Moment>
    </MDBTooltip>
  )
}

CreatedAt.propTypes = {
  createdAt: PropTypes.string.isRequired
}

export default CreatedAt
