import React from 'react'
import PropTypes from 'prop-types'
import 'prismjs'
import { PrismCode } from 'react-prism'

import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn
} from 'mdbreact'

export default class POPOrderStatusModal extends React.Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }
  render () {
    let { onClose, status } = this.props
    return (
      <MDBModal isOpen={true} toggle={onClose} size="lg">
        <MDBModalHeader toggle={onClose}>
          Print For Less Order Details
        </MDBModalHeader>
        <MDBModalBody>
          <PrismCode component="pre" className="language-json">
            {JSON.stringify(JSON.parse(status), null, 2)}
          </PrismCode>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color="primary"
            type="button"
            onClick={() => onClose()}
          >
            Ok
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}
