import React, { Component } from 'react'

import { Card, CardBody, MDBIcon } from 'mdbreact'

import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import UserList from '../components/UserList'
import Pagination from '../components/Pagination'

const PAGE_SIZE = 10

const GET_USERS = gql`
query Users($first: Int, $after: String, $search: String) {
  users(first: $first, after: $after, search: $search) {
    totalCount
    edges {
      node {
        id
        name
        email
        lastLogin
        lastLoginWith
        isPaypalVerified
        createdAt
        actorRepresentation
        storeCount
      }
    }
  }
}
`

export class UserListPage extends Component {
  static propTypes = {}

  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      search: '',
      currentSearch: '',
      typingTimeout: 0
    }
  }

  typeSearch (value) {
    let { typingTimeout } = this.state
    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    this.setState({
      search: value,
      typing: true,
      typingTimeout: setTimeout(() => {
        this.search()
      }, 500)
    })
  }

  search () {
    this.setState({
      currentSearch: this.state.search,
      currentPage: 1,
      typing: false
    })
  }

  render () {
    let { search, currentSearch, currentPage } = this.state
    return (
      <Card>
        <CardBody>
          <h1>Users</h1>

          <div className="input-group md-form form-sm form-1 pl-0">
            <div className="input-group-prepend">
              <span className="input-group-text lighten-3" id="basic-text1">
                <MDBIcon className="text-white" icon="search" />
              </span>
            </div>
            <input
              className="form-control my-0 py-1"
              type="text"
              placeholder="Search"
              aria-label="Search"
              value={search}
              onChange={event => this.typeSearch(event.target.value)}
            />
          </div>

          <Query
            query={GET_USERS}
            variables={{ first: PAGE_SIZE, search: currentSearch }}
            fetchPolicy="network-only"
          >
            {({ loading, error, data, fetchMore, refetch }) => {
              if (loading) return 'Loading...'
              if (error) return `Error! ${error.message}`

              return (
                <Pagination
                  pageSize={PAGE_SIZE}
                  totalCount={data.users.totalCount || 0}
                  currentPage={currentPage}
                  fetchMore={fetchMore}
                  setCurrentPage={page =>
                    this.setState({ currentPage: page })
                  }
                >
                  <UserList
                    entries={data.users || []}
                    onRowClicked={rowId => this.showEditModal(rowId)}
                  />
                </Pagination>
              )
            }}
          </Query>
        </CardBody>
      </Card>
    )
  }
}

export default UserListPage
