import React from 'react'
import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'
import moment from 'moment'
import omit from 'lodash/omit'

import { DateRangePicker } from 'react-dates'

export const START_DATE = 'startDate'
export const END_DATE = 'endDate'
export const HORIZONTAL_ORIENTATION = 'horizontal'
export const ANCHOR_LEFT = 'left'

const propTypes = {
  autoFocus: PropTypes.bool,
  autoFocusEndDate: PropTypes.bool,
  stateDateWrapper: PropTypes.func,
  initialStartDate: momentPropTypes.momentObj,
  initialEndDate: momentPropTypes.momentObj,
  onChange: PropTypes.func
}

const defaultProps = {
  autoFocus: false,
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,

  // input related props
  startDateId: START_DATE,
  startDatePlaceholderText: 'Start Date',
  endDateId: END_DATE,
  endDatePlaceholderText: 'End Date',
  disabled: false,
  required: false,
  screenReaderInputMessage: '',
  showClearDates: false,
  showDefaultInputIcon: false,
  customInputIcon: null,
  customArrowIcon: null,
  customCloseIcon: null,
  block: false,
  small: true,
  regular: false,

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: HORIZONTAL_ORIENTATION,
  anchorDirection: ANCHOR_LEFT,
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick () {},
  onNextMonthClick () {},
  onClose () {},

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  minimumNights: 1,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isOutsideRange: day => day.isAfter(moment()),
  isDayHighlighted: () => false,

  // internationalization
  displayFormat: () => moment.localeData().longDateFormat('LL'),
  monthFormat: 'MMMM YYYY',
  //   phrases: DateRangePickerPhrases,

  // daySize: 19,
  stateDateWrapper: date => date
}

class DateRangePickerWrapper extends React.Component {
  constructor (props) {
    super(props)

    let focusedInput = null
    if (props.autoFocus) {
      focusedInput = START_DATE
    } else if (props.autoFocusEndDate) {
      focusedInput = END_DATE
    }

    this.state = {
      focusedInput,
      startDate: props.initialStartDate,
      endDate: props.initialEndDate
    }

    this.onDatesChange = this.onDatesChange.bind(this)
    this.onFocusChange = this.onFocusChange.bind(this)
  }

  onDatesChange ({ startDate, endDate }) {
    const { stateDateWrapper, onChange } = this.props
    this.setState({
      startDate: startDate && stateDateWrapper(startDate),
      endDate: endDate && stateDateWrapper(endDate)
    })
    onChange({ startDate, endDate })
  }

  onFocusChange (focusedInput) {
    this.setState({ focusedInput })
  }

  // isSameDay (a, b) {
  //   if (!moment.isMoment(a) || !moment.isMoment(b)) return false
  //   // Compare least significant, most likely to change units first
  //   // Moment's isSame clones moment inputs and is a tad slow
  //   return (
  //     a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
  //   )
  // }

  renderDatePresets () {
    const presets = [
      { text: '3 days', start: moment().subtract(3, 'day'), end: moment() },
      { text: '1 Week', start: moment().subtract(1, 'week'), end: moment() },
      { text: '1 Month', start: moment().subtract(1, 'month'), end: moment() },
      { text: '3 Months', start: moment().subtract(3, 'month'), end: moment() }
    ]

    return (
      <div style={{ margin: '0 0 10px 20px' }}>
        {presets.map(({ text, start, end }) => {
          // const isSelected =
          //   this.isSameDay(start, startDate) && this.isSameDay(end, endDate)
          return (
            <button
              key={text}
              type="button"
              className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default"
              style={{ padding: '5px', marginRight: '5px' }}
              onClick={() =>
                this.onDatesChange({ startDate: start, endDate: end })
              }
            >
              {text}
            </button>
          )
        })}
      </div>
    )
  }

  render () {
    const { focusedInput, startDate, endDate } = this.state

    // autoFocus, autoFocusEndDate, initialStartDate and initialEndDate are helper props for the
    // example wrapper but are not props on the SingleDatePicker itself and
    // thus, have to be omitted.
    const props = omit(this.props, [
      'autoFocus',
      'autoFocusEndDate',
      'initialStartDate',
      'initialEndDate',
      'stateDateWrapper',
      'onChange'
    ])

    return (
      <DateRangePicker
        {...props}
        renderCalendarInfo={() => this.renderDatePresets()}
        onDatesChange={this.onDatesChange}
        onFocusChange={this.onFocusChange}
        focusedInput={focusedInput}
        startDate={startDate}
        endDate={endDate}
        withPortal
      />
    )
  }
}

DateRangePickerWrapper.propTypes = propTypes
DateRangePickerWrapper.defaultProps = defaultProps

export default DateRangePickerWrapper
