import React from 'react'
import PropTypes from 'prop-types'
import { MDBBtn, MDBModal, MDBModalBody } from 'mdbreact'

const DownloadModal = ({ downloadURL, toggle }) => (
  <MDBModal isOpen={true} toggle={toggle} frame position="top">
    <MDBModalBody className="text-center">
      <MDBBtn color="primary" href={downloadURL} onClick={toggle}>
        Start download
      </MDBBtn>
      <MDBBtn color="secondary" onClick={toggle}>
        Close
      </MDBBtn>
    </MDBModalBody>
  </MDBModal>
)
DownloadModal.propTypes = {
  downloadURL: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired
}

export default DownloadModal
