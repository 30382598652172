import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import gql from 'graphql-tag'
import * as yup from 'yup'
import { withFormik } from 'formik'
import { compose, withApollo, graphql } from 'react-apollo'
import { serverValidationToForm } from '../../utils/formUtils'

import TemplateStoreSelect from '../elements/TemplateStoreSelect'

import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn
} from 'mdbreact'

const CAMPAIGN_MUTATION = gql`
  mutation CreateCampaign(
    $code: String
    $name: String!
    $numGeneratedClaimables: Int
    $templateStoreId: ID!
    $isEnabled: Boolean
  ) {
    createCampaign(
      input: {
        code: $code
        name: $name
        numGeneratedClaimables: $numGeneratedClaimables
        templateStoreId: $templateStoreId
        isEnabled: $isEnabled
      }
    ) {
      errors {
        field
        messages
      }
    }
  }
`

const CAMPAIGN_UPDATE_MUTATION = gql`
  mutation UpdateCampaign(
    $id: ID!
    $code: String
    $name: String
    $numGeneratedClaimables: Int
    $templateStoreId: ID
    $isEnabled: Boolean
  ) {
    updateCampaign(
      input: {
        id: $id
        code: $code
        name: $name
        numGeneratedClaimables: $numGeneratedClaimables
        templateStoreId: $templateStoreId
        isEnabled: $isEnabled
      }
    ) {
      errors {
        field
        messages
      }
    }
  }
`

const enhancer = withFormik({
  validationSchema: yup.object().shape({
    code: yup
      .string()
      .uppercase()
      .min(5)
      .max(5)
      .label('Code'),
    name: yup
      .string()
      .required()
      .label('Name'),
    templateStoreId: yup
      .string()
      .required()
      .label('Template store'),
    numGeneratedClaimables: yup
      .number()
      .min(0)
      .max(5)
      .required()
      .label('Number of invite codes sent after claiming'),
    isEnabled: yup.bool().label('isEnabled')
  }),

  mapPropsToValues: props => {
    if (props.editCampaignItem) {
      return {
        templateStoreId: {
          value: props.editCampaignItem.templateStore.id,
          label: props.editCampaignItem.templateStore.name,
          obj: props.editCampaignItem.templateStore
        },
        ...props.editCampaignItem
      }
    }
    return {
      id: null,
      code: '',
      name: '',
      templateStoreId: '',
      numGeneratedClaimables: 0,
      isEnabled: props.isEnabled || true
    }
  },

  handleSubmit: async (values, { props, setSubmitting, setFieldError }) => {
    let mutation, errors
    if (values.id) {
      mutation = await props.updateCampaign({
        variables: {
          id: values.id,
          code: values.code.toUpperCase(),
          name: values.name,
          isEnabled: values.isEnabled,
          numGeneratedClaimables: values.numGeneratedClaimables,
          templateStoreId: values.templateStoreId.value
        }
      })
      errors = mutation.data.updateCampaign.errors
    } else {
      mutation = await props.createCampaign({
        variables: {
          code: values.code.toUpperCase(),
          name: values.name,
          isEnabled: values.isEnabled,
          numGeneratedClaimables: values.numGeneratedClaimables,
          templateStoreId: values.templateStoreId.value
        }
      })
      errors = mutation.data.createCampaign.errors
    }

    setSubmitting(false)

    if (errors) {
      serverValidationToForm({
        errors,
        setFieldError,
        errorFieldMap: { response: 'code' }
      })
    } else {
      props.onClose()
    }
  }
})

class CampaignModal extends React.Component {
  static propTypes = {
    values: PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      isEnabled: PropTypes.bool.isRequired,
      numGeneratedClaimables: PropTypes.any.isRequired,
      templateStoreId: PropTypes.any.isRequired
    }),
    editCampaignItem: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      isEnabled: PropTypes.bool.isRequired,
      numGeneratedClaimables: PropTypes.any.isRequired,
      templateStore: PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    }),
    client: PropTypes.object.isRequired,
    status: PropTypes.string,
    errors: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setStatus: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool
  }
  render () {
    return (
      <MDBModal isOpen={true} toggle={this.props.onClose} size="lg">
        <form onSubmit={this.props.handleSubmit} noValidate>
          <MDBModalHeader toggle={this.props.onClose}>
            {this.props.editCampaignItem ? 'Modify' : 'Create'} a campaign
          </MDBModalHeader>
          <MDBModalBody>
            <div className="form-group">
              <label htmlFor="code">Code</label>
              <input
                id="code"
                name="code"
                type="text"
                disabled={!!this.props.editCampaignItem}
                value={this.props.values.code}
                onBlur={this.props.handleBlur}
                onChange={this.props.handleChange}
                className={classNames({
                  'form-control': true,
                  'is-invalid':
                    this.props.status === 'submitted' && this.props.errors.code
                })}
              />
              {this.props.status === 'submitted' && this.props.errors.code && (
                <div className="invalid-feedback d-block">
                  {this.props.errors.code}
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                id="name"
                name="name"
                type="text"
                value={this.props.values.name}
                onBlur={this.props.handleBlur}
                onChange={this.props.handleChange}
                className={classNames({
                  'form-control': true,
                  'is-invalid':
                    this.props.status === 'submitted' && this.props.errors.name
                })}
              />
              {this.props.status === 'submitted' && this.props.errors.name && (
                <div className="invalid-feedback d-block">
                  {this.props.errors.name}
                </div>
              )}
            </div>
            <div className="form-group">
              <TemplateStoreSelect
                client={this.props.client}
                value={this.props.values.templateStoreId}
                errors={this.props.errors}
                status={this.props.status}
                onChange={value =>
                  this.props.setFieldValue('templateStoreId', value)
                }
                handleBlur={this.props.handleBlur}
              />
            </div>
            <div className="form-group">
              <label htmlFor="numGeneratedClaimables">
                Number of invite codes sent after claiming
              </label>
              <input
                id="numGeneratedClaimables"
                name="numGeneratedClaimables"
                type="number"
                value={this.props.values.numGeneratedClaimables}
                onBlur={this.props.handleBlur}
                onChange={this.props.handleChange}
                className={classNames({
                  'form-control': true,
                  'is-invalid':
                    this.props.status === 'submitted' &&
                    this.props.errors.numGeneratedClaimables
                })}
              />
              {this.props.status === 'submitted' &&
                this.props.errors.numGeneratedClaimables && (
                <div className="invalid-feedback d-block">
                  {this.props.errors.numGeneratedClaimables}
                </div>
              )}
            </div>
            <div className="form-group form-check">
              <input
                id="isEnabled"
                name="isEnabled"
                type="checkbox"
                checked={this.props.values.isEnabled}
                onBlur={this.props.handleBlur}
                onChange={this.props.handleChange}
                className={classNames({
                  'form-check-input': true,
                  'is-invalid':
                    this.props.status === 'submitted' &&
                    this.props.errors.isEnabled
                })}
              />
              <label className="form-check-label" htmlFor="isEnabled">
                Enabled
              </label>
              {this.props.status === 'submitted' &&
                this.props.errors.isEnabled && (
                <div className="invalid-feedback d-block">
                  {this.props.errors.isEnabled}
                </div>
              )}
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="primary"
              disabled={this.props.isSubmitting}
              type="submit"
              onClick={() => this.props.setStatus('submitted')}
            >
              Save
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </MDBModal>
    )
  }
}

export default compose(
  withApollo,
  graphql(CAMPAIGN_MUTATION, { name: 'createCampaign' }),
  graphql(CAMPAIGN_UPDATE_MUTATION, { name: 'updateCampaign' }),
  enhancer
)(CampaignModal)
