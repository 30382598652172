import { ApolloLink, Observable } from 'apollo-link'

import auth from '../utils/auth'

// import { getToken, refreshToken } from '../services/auth'

export class AuthLink extends ApolloLink {
  injectClient = (client) => {
    this.client = client
  }

  refreshToken = () => {
    return auth.refresh(this.client)
  }

  setTokenHeader = (operation) => {
    const token = auth.getToken()
    if (token) {
      operation.setContext(({ headers }) => ({
        headers: {
          authorization: `JWT ${token}`,
          ...headers
        }
      }))
    }
  }

  request (operation, forward) {
    if (operation.operationName === 'TokenAuth' || operation.operationName === 'RefreshToken') {
      return forward(operation)
    }

    // check token expires within 10 minutes
    // if so, refresh it
    if (auth.expiresWithin(10)) {
      return new Observable(observer => {
        let subscription, innerSubscription

        try {
          this.refreshToken().then(success => {
            if (success) {
              auth.login(success.data.refreshToken.token, success.data.refreshToken.refresh)
              this.setTokenHeader(operation)
            }
            subscription = forward(operation).subscribe({
              next: observer.next.bind(observer),
              complete: observer.complete.bind(observer),
              error: observer.error.bind(observer)
            })
          })
        } catch (e) {
          observer.error(e)
        }

        return () => {
          if (subscription) subscription.unsubscribe()
          if (innerSubscription) innerSubscription.unsubscribe()
        }
      })
    } else {
      this.setTokenHeader(operation)

      return forward(operation)
    }
  }
}
