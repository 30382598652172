import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Navbar, NavbarBrand, NavbarNav, NavbarToggler, Collapse, NavItem, Fa } from 'mdbreact'

import currentUser from '../utils/currentUser'
import auth from '../utils/auth'

class TopNavigation extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    currentUser: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      collapse: false
    }
    this.onClick = this.onClick.bind(this)
    this.toggle = this.toggle.bind(this)
  }

  onClick () {
    this.setState({
      collapse: !this.state.collapse
    })
  }

  toggle () {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  render () {
    return (
      <Navbar className="flexible-navbar" light expand="md" scrolling>
        <NavbarBrand href="/">
          <strong>Team Portal</strong>
        </NavbarBrand>
        <NavbarToggler onClick = {() => this.onClick() } />
        <Collapse isOpen = { this.state.collapse } navbar>
          <NavbarNav left>
            {/*
            <NavItem active>
              <NavLink to="#">Home</NavLink>
            </NavItem>
            <NavItem>
              <a rel="noopener noreferrer" className="nav-link Ripple-parent" href="https://mdbootstrap.com/docs/react/" target="_blank">About MDB</a>
            </NavItem>
            <NavItem>
              <a rel="noopener noreferrer" className="nav-link Ripple-parent" href="https://mdbootstrap.com/docs/react/getting-started/download/" target="_blank">Free download</a>
            </NavItem>
            <NavItem>
              <a rel="noopener noreferrer" className="nav-link Ripple-parent" href="https://mdbootstrap.com/bootstrap-tutorial/" target="_blank">Free tutorials</a>
            </NavItem>
            */}
          </NavbarNav>
          <NavbarNav right>
            <NavItem>
              <a href="/login" className="nav-link navbar-link" rel="noopener noreferrer" onClick={() => {
                auth.logout()
                this.props.history.push('/login')
              }}><Fa icon="sign-out" /> Log out <span className="text-muted">({ this.props.currentUser.email })</span></a>
            </NavItem>
          </NavbarNav>
        </Collapse>
      </Navbar>
    )
  }
}

export default currentUser(TopNavigation)
