import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'
import * as yup from 'yup'
import classNames from 'classnames'
import { graphql, compose, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  Input,
  Button
} from 'mdbreact'
import { serverValidationToForm } from '../utils/formUtils'

import auth from '../utils/auth'

import './Login.scss'

const TOKEN_AUTH = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(input: { email: $email, password: $password }) {
      token
      refresh
      errors {
        field
        messages
      }
    }
  }
`

const enhancer = withFormik({
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email()
      .required(),
    password: yup.string().required()
  }),

  mapPropsToValues: props => {
    return {
      email: '',
      password: ''
    }
  },

  handleSubmit: async (values, { props, setSubmitting, setFieldError }) => {
    const tokenAuth = await props.tokenAuth({
      variables: { email: values.email, password: values.password }
    })

    setSubmitting(false)

    if (tokenAuth.data.tokenAuth.errors) {
      serverValidationToForm({
        errors: tokenAuth.data.tokenAuth.errors,
        setFieldError,
        errorFieldMap: { response: 'email' }
      })
    } else {
      props.client.clearStore()
      auth.login(tokenAuth.data.tokenAuth.token, tokenAuth.data.tokenAuth.refresh)
      props.history.push('/')
    }
  }
})

class Login extends Component {
  static propTypes = {
    touched: PropTypes.object,
    errors: PropTypes.object,
    values: PropTypes.object,
    status: PropTypes.string,
    isSubmitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setStatus: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired
  }

  componentDidMount () {
    auth.logout()
  }

  render () {
    return (
      <div className="Login-container py-5">
        <MDBContainer className="pt-5">
          <MDBRow className="justify-content-md-center">
            <MDBCol md="6" lg="5">
              <MDBCard>
                <MDBCardBody className="px-4">
                  <MDBCardTitle className="text-center">HausMart</MDBCardTitle>
                  <MDBCardText className="text-center">Team Login</MDBCardText>
                  <form
                    className={classNames({
                      'was-validated': this.props.status === 'submitted'
                    })}
                    onSubmit={this.props.handleSubmit}
                    noValidate
                  >
                    <div className="pb-2">
                      <Input
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        value={this.props.values.email}
                        onBlur={this.props.handleBlur}
                        onChange={this.props.handleChange}
                        containerClass="mb-1"
                        className={classNames({
                          'is-invalid':
                            this.props.status === 'submitted' &&
                            this.props.errors.email
                        })}
                        required
                      />
                      {this.props.status === 'submitted' &&
                        this.props.errors.email && (
                        <div className="invalid-feedback d-block">
                          {this.props.errors.email}
                        </div>
                      )}
                    </div>

                    <div className="pb-2">
                      <Input
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        value={this.props.values.password}
                        onBlur={this.props.handleBlur}
                        onChange={this.props.handleChange}
                        containerClass="mb-1"
                        className={classNames({
                          'is-invalid':
                            this.props.status === 'submitted' &&
                            this.props.errors.password
                        })}
                        required
                      />
                      {this.props.status === 'submitted' &&
                        this.props.errors.password && (
                        <div className="invalid-feedback d-block">
                          {this.props.errors.password}
                        </div>
                      )}
                    </div>

                    <div className="text-center">
                      <Button
                        disabled={this.props.isSubmitting}
                        type="submit"
                        onClick={() => this.props.setStatus('submitted')}
                      >
                        Login
                      </Button>
                    </div>
                  </form>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    )
  }
}

export default compose(
  withApollo,
  graphql(TOKEN_AUTH, { name: 'tokenAuth' }),
  enhancer
)(Login)
