import React, { useState } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBIcon
} from 'mdbreact'

const PRODUCT_QUERY = gql`
  query Products($barcode: String) {
    products(scannedBarcode: $barcode) {
      totalCount
      edges {
        node {
          id
          name
          description
          images {
            id
            imageType
            isPrimary
            url
            thumbnail
          }
        }
      }
    }
  }
`

const DELETE_ISSUE = gql`
  mutation DeleteScannedBarcodeLog($id: ID!) {
    deleteScannedBarcodeLog(input: { id: $id }) {
      success
    }
  }
`

const MOVE_TO_CATALOG_MUTATION = gql`
mutation MoveScannedBarcode($fromScannedBarcodeLogId: ID!, $productName: String!) {
  moveScannedBarcode(input: {fromScannedBarcodeLogId: $fromScannedBarcodeLogId, productName: $productName}) {
    scannedBarcode {
      id
      productName
    }
  }
}`

const BarcodeImageModal = ({ values, onClose, client }) => {
  const [showExisting, setShowExisting] = useState(false)
  const [productName, setProductName] = useState(values.productName)

  const handleDeleteIssue = (id) => {
    if (!window.confirm('Are you sure you want to delete this?')) {
      return
    }
    client.mutate({
      mutation: DELETE_ISSUE,
      variables: {
        id: id
      }
    }).then(res => {
      onClose(res.data.deleteScannedBarcodeLog.success)
    })
  }

  const handleMoveToCatalog = (id) => {
    client.mutate({
      mutation: MOVE_TO_CATALOG_MUTATION,
      variables: {
        fromScannedBarcodeLogId: id,
        productName: productName
      }
    }).then(res => {
      onClose(true)
    })
  }

  return (
    <MDBModal isOpen={true} toggle={onClose} size="lg">
      <MDBModalHeader toggle={onClose}>Edit Issue</MDBModalHeader>
      <form noValidate>
        <MDBModalBody>
          <div className="form-group">
            <label htmlFor="code">Barcode</label>
            <input
              id="barcode"
              name="barcode"
              type="text"
              disabled={true}
              value={values.barcode}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              name="name"
              type="text"
              value={productName}
              onChange={event => setProductName(event.target.value)}
              className="form-control"
            />
          </div>
          <Query
            query={PRODUCT_QUERY}
            variables={{ barcode: values.barcode }}
          >
            {({ loading, error, data }) => {
              if (loading) return 'Loading...'
              if (error) return `Error! ${error.message}`

              return (
                <div data-spy="scroll" data-offset="0">
                  <button
                    className="btn btn-link"
                    type="button"
                    onClick={() => setShowExisting(!showExisting)}
                  >
                    <MDBIcon
                      icon={showExisting ? 'minus-square' : 'plus-square'}
                    />{' '}
                    Existing products ({data.products.totalCount})
                  </button>
                  {showExisting && (
                    <div className="card-columns">
                      {data.products.edges.map(({ node }) => (
                        <MDBCard key={node.id}>
                          <MDBCardBody className="mx-auto">
                            <img
                              alt="Product name"
                              src={node.images[0].thumbnail}
                              style={{
                                maxWidth: '200px',
                                maxHeight: '362px'
                              }}
                            />
                            {node.name}
                          </MDBCardBody>
                        </MDBCard>
                      ))}
                    </div>
                  )}
                </div>
              )
            }}
          </Query>
        </MDBModalBody>
        <MDBModalFooter className="d-flex justify-content-between">
          <MDBBtn color="danger" onClick={() => handleDeleteIssue(values.id)}>
            Delete
          </MDBBtn>
          <MDBBtn color="primary" onClick={() => handleMoveToCatalog(values.id)}>
            Add to catalog
          </MDBBtn>
        </MDBModalFooter>
      </form>
    </MDBModal>
  )
}

BarcodeImageModal.propTypes = {
  values: PropTypes.shape({
    productName: PropTypes.string.isRequired,
    barcode: PropTypes.string.isRequired
  }),
  onClose: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired
}

export default BarcodeImageModal
