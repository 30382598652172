import React, { Component } from 'react'
import PropTypes from 'prop-types'
import currentUser from '../utils/currentUser'

import { MDBCard, MDBCardBody, MDBCardText } from 'mdbreact'

import HomeStoreRevenueReport from '../components/HomeStoreRevenueReport'

export class Home extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string
    }).isRequired
  }
  render () {
    return (
      <div>
        <MDBCard className="mb-4">
          <MDBCardBody>
            <MDBCardText className="text-center"><span style={{ color: '#000' }} className="m-0 h5 d-block">Welcome, { this.props.currentUser.name }</span></MDBCardText>
          </MDBCardBody>
        </MDBCard>

        <HomeStoreRevenueReport />

      </div>
    )
  }
}

export default currentUser(Home)
