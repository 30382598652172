import React from 'react'
import PropTypes from 'prop-types'

import Moment from 'react-moment'
import { formatDateTime } from '../utils/dateUtils'

import { Table, TableBody, TableHead, MDBTooltip, MDBIcon } from 'mdbreact'

const LoginIcon = ({ method }) => {
  if (!method) return null
  let lmethod = method.toLowerCase()

  return (
    <MDBTooltip placement="bottom" tag="div" tooltipContent={lmethod}>
      <MDBIcon
        icon={lmethod === 'password' ? 'key' : lmethod}
        style={{ color: 'black' }}
      />
    </MDBTooltip>
  )
}
LoginIcon.propTypes = {
  method: PropTypes.oneOf([
    'google',
    'GOOGLE',
    'facebook',
    'FACEBOOK',
    'password',
    'PASSWORD'
  ])
}

const UserList = ({ entries, onRowClicked }) => (
  <div>
    <span>{entries.totalCount} results</span>
    <Table>
      <TableHead textWhite color="unique-color-dark">
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Last Login</th>
          <th className="text-center">Last Login With</th>
          <th className="text-center">Is PayPal Verified</th>
          <th className="text-right">Store Count</th>
          <th className="text-right">Created</th>
        </tr>
      </TableHead>

      <TableBody color="white">
        {entries.edges.map(({ node }) => (
          <tr key={node.id}>
            <td className="small">{node.name}</td>
            <td className="small muted">{node.email}</td>
            <td>{node.lastLogin &&
              <MDBTooltip
                placement="bottom"
                tag="div"
                tooltipContent={formatDateTime(node.lastLogin)}
              >
                <Moment fromNow>{node.lastLogin}</Moment>
              </MDBTooltip>
            }
            </td>
            <td className="text-center"><LoginIcon
              method={node.lastLoginWith} />
            </td>
            <td className="text-center">
              <MDBIcon
                icon={node.isPaypalVerified ? 'check' : 'times'}
                fixed
                className={node.isPaypalVerified ? 'text-success' : 'text-danger'}
              />
            </td>
            <td className="text-right">{node.storeCount}</td>
            <td className="text-right">
              <MDBTooltip
                placement="bottom"
                tag="div"
                tooltipContent={formatDateTime(node.createdAt)}
              >
                <Moment fromNow>{node.createdAt}</Moment>
              </MDBTooltip>
            </td>
          </tr>
        ))}
      </TableBody>
    </Table>
  </div>
)

UserList.propTypes = {
  entries: PropTypes.shape({
    totalCount: PropTypes.number.isRequired,
    edges: PropTypes.array.isRequired
  })
}

export default UserList
