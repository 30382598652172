import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Moment from 'react-moment'
import moment from 'moment'
import classNames from 'classnames'

import { Table, TableBody, TableHead, MDBTooltip, MDBIcon, MDBBadge } from 'mdbreact'

export default class ClaimList extends Component {
  static propTypes = {
    entries: PropTypes.object.isRequired,
    qrModal: PropTypes.func
  }

  render () {
    return (
      <div>
        <span>{this.props.entries.totalCount} results</span>
        <Table>
          <TableHead textWhite color="unique-color-dark">
            <tr>
              <th>#</th>
              <th>Status</th>
              <th>Campaign</th>
              <th>Template</th>
              <th>Batch</th>
              <th>Created</th>
              <th>Created By</th>
            </tr>
          </TableHead>

          <TableBody color="white">
            {this.props.entries.edges.map(claim => (
              <tr key={claim.node.id}>
                <td className={classNames({
                  'small': true,
                  'text-monospace': true,
                  'text-muted': !claim.node.store
                })}>
                  { claim.node.store
                    ? <a className="text-hausmart-pink" href={ claim.node.store.webUrl }>{ claim.node.shortId }</a>
                    : claim.node.shortId}
                </td>
                <td className="small">
                  { claim.node.isClaimed ? <MDBBadge pill color="success">Claimed</MDBBadge> : ''}
                  { claim.node.claimIsActive ? <MDBBadge pill color="success">Claim in progress</MDBBadge> : ''}
                  { !claim.node.isClaimed && !claim.node.claimIsActive ? <MDBBadge pill color="light">Unclaimed</MDBBadge> : ''}
                </td>
                <td>{claim.node.campaign ? claim.node.campaign.name : ''}</td>
                <td>{claim.node.templateStore.name}</td>
                <td>{claim.node.batchName
                  ? <span>
                    {claim.node.batchName} <MDBIcon icon="qrcode" onClick={ () => this.props.qrModal(claim.node.batchName) } style={{ cursor: 'pointer' }} />
                  </span>
                  : ''} </td>
                <td>
                  <MDBTooltip
                    placement="bottom"
                    tag="div"
                    tooltipContent={moment(claim.node.createdAt).tz('Atlantic/Reykjavik').format('lll z')}>
                    <Moment fromNow>{claim.node.createdAt}</Moment>
                  </MDBTooltip>
                </td>
                <td>{claim.node.createdBy ? claim.node.createdBy.name : ''}</td>
              </tr>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }
}
